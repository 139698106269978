import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery, useTheme, Typography, Box, CardMedia } from "@mui/material";
import { fetchArtistArtsAsync } from "../../redux/ArtSlice";
import { fetchArtistInfo } from "../../redux/ArtistSlice";
import ArtCopy from "../arts/art/ArtCopy";
import "./artistInfo.css";
import { followArtist } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import CenteredCircularProgress from '../circularProgress/CenteredCircularProgress';
import LoadingSpinner from "../circularProgress/LoadingSpinner";
import '../../global.css';

function ArtistInfo() {
  const { id } = useParams();
  const navigate = useNavigate();
  // const location = useLocation();
  // const { obj } = location.state;

  const obj = useSelector((state) => state.artist.artistInfo)
  const statusArtistInfo = useSelector((state) => state.artist.statusArtistInfo)
  const message = useSelector((state) => state.artist.message)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchArtistInfo(id))
  }, [id])

  const { artistArts, statusArtistArt } = useSelector((state) => state.art);

  const user = useSelector((state) => state.user.user)

  const isFollowing = user?.followingArtist?.includes(obj._id)

  const handleFollow = (event) => {
    event.preventDefault(); // Prevent default behavior if this is a form submission or link click
    event.stopPropagation(); // Stop the event from bubbling up

    if (user) {
      dispatch(followArtist({ userData: user, artistId: obj._id }))
    } else {
      navigate('/user/auth/signin')
    }
  };


  useEffect(() => {
    dispatch(fetchArtistArtsAsync(id));
  }, [dispatch, id]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isProTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const [expanded, setExpanded] = useState(false);
  const characterLimit = 100; // Limit of characters before showing "See More"

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderText = () => {
    if (expanded) {
      return obj.artistInfo;
    }
    return obj.artistInfo.length > characterLimit
      ? `${obj.artistInfo.slice(0, characterLimit)}...`
      : obj.artistInfo;
  };


  return (
    <>
      {statusArtistInfo === 'idle' || statusArtistInfo === 'loading' ?
        <div style={{ height: '80vh', width: '100%', display: 'flex', outline: "none", fontFamily: 'lobster', fontSize: isMobile ? '1.1rem' : '1.2rem', justifyContent: 'center', alignItems: "center" }}>
          <div className="dot-container">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div> :
        statusArtistInfo === 'failed' ?
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '1rem', fontSize: isMobile ? '1.2rem' : '1.2rem' }}>{message}</div> :
          <div style={{}}>
            <Box style={{
              display: 'flex', flexDirection: "row",
              height: isMobile ? "10rem" : isTablet ? '11rem' : isProTablet ? '12rem' : "20rem",
              // height: isMobile ? '15rem' : isTablet ? '16rem' : isProTablet ? '18rem' : '25rem', 
              justifyContent: 'center',
              // flexWrap: "wrap",
              paddingInline: isMobile ? '2rem' : isTablet ? '3rem' : isProTablet ? '8rem' : '11rem',
              paddingTop: isMobile ? '' : isTablet ? '0.5rem' : isProTablet ? '1rem' : '2rem', paddingBottom: isMobile ? '' : isTablet ? '0.5rem' : isProTablet ? '1rem' : '2rem',
              marginBottom: '2rem',
              // gap: isMobile ? '1rem' : isTablet ? '2rem' : "5rem",
            }}>
              <div style={{ pointerEvents: 'none', width: isMobile ? "10rem" : isTablet ? '11rem' : isProTablet ? '12rem' : "20rem", minWidth: isMobile ? "10rem" : isTablet ? '11rem' : isProTablet ? '12rem' : "20rem" }}>
                <CardMedia
                  component="img"
                  draggable="false"
                  onDragStart={(e) => e.preventDefault()}
                  style={{ pointerEvents: 'none', height: "100%", objectFit: 'contain' }}
                  image={obj.selectedFile}
                  alt={`Image of ${obj.artistName}`}
                />
              </div>
              <div style={{
                overflow: 'hidden',
                // padding: '1rem',
                marginLeft: '1rem',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignContent: 'center',
                // border: 'solid'
                // backgroundColor: 'rgba(226, 223, 210, .5)',
                // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                // width: isMobile ? "10rem" : "20rem"
              }}>
                <div className="title" style={{ marginBottom: '0.5rem', fontSize: isMobile ? '1.1rem' : '1.5rem' }}>{obj.artistName}</div>
                {obj?.lifeSpan && <div className="textBody" style={{ marginBottom: '0.5rem' }}>
                  {obj.lifeSpan}
                </div>}
                <div onClick={handleFollow} style={{ width: '5rem', marginBottom: '1rem', color: 'grey', fontSize: '0.8rem', textTransform: 'uppercase', cursor: "pointer", textAlign: 'center', padding: '0.1rem', border: '.01rem solid grey', display: 'flex', justifyContent: 'center' }}>
                  {isFollowing ? 'Following' : 'Follow'}
                </div>
                {!(isMobile) &&
                  < div
                    className="textBody"
                    style={{
                      // fontFamily: 'lobster',
                      // fontSize: isMobile ? '0.9rem' : '1.1rem',
                      height: isMobile ? '10rem' : isTablet ? '15rem' : '18rem',
                      textAlign: isMobile ? '' : 'justify',
                      textAlign: 'justify', fontSize: '1.1rem',
                      overflow: 'auto'  // Set overflow to auto to enable scrolling
                    }}
                  >
                    {obj.artistInfo}
                  </div>}
              </div>

            </Box >
            {(isMobile) &&
              <div style={{
                paddingInline: isMobile ? '' : isTablet ? '3rem' : isProTablet ? '8rem' : '11rem',
                paddingTop: isMobile ? '' : isTablet ? '0.5rem' : isProTablet ? '1rem' : '2rem', paddingBottom: isMobile ? '' : isTablet ? '0.5rem' : isProTablet ? '1rem' : '2rem',
                marginBottom: '2rem', display: 'flex', justifyContent: 'center', flex: 1,
              }}>
                <div>
                  <div
                    className="textBody"
                    style={{
                      textAlign: isMobile ? '' : '',
                      fontSize: '1.1rem',
                      overflow: 'hidden',
                      width: '20rem',
                    }}
                  >
                    {renderText()}
                    {obj.artistInfo.length > characterLimit && (
                      <span
                        onClick={toggleExpanded}
                        style={{ color: '', cursor: 'pointer', marginLeft: '0.5rem' }}
                      >
                        {expanded ? 'See Less' : 'See More'}
                      </span>
                    )}
                  </div>
                </div>
              </div >
            }
            <div >
              {
                statusArtistArt === 'idle' || statusArtistArt === 'loading' ?
                  <div style={{ height: '20vh', display: 'flex', outline: "none", fontFamily: 'lobster', fontSize: '1.2rem', justifyContent: 'center', alignItems: "center" }}>
                    <div className="dot-container">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                  :
                  artistArts.length > 0 && <div style={{ paddingInline: isMobile ? '' : isTablet ? '' : "" }}>
                    {/* <div
                      className="heading"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // border: 'solid'
                        // margin: '1rem'
                      }}
                    >
                      <p className="title" style={{}}>
                        Art Collections
                      </p>
                    </div> */}
                    <div>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: isMobile ? '1rem' : isTablet ? '2rem' : "4rem",
                          justifyContent: "center",
                          marginBottom: "2rem",
                          paddingInline: isMobile ? '' : '5rem'
                        }}
                      >
                        {artistArts.map((item) => (
                          <div
                            // className="mapDiv"
                            style={{
                              // width: isMobile ? "10rem" : "15rem",
                              // height: isMobile ? "13rem" : "18rem",
                              // marginBottom: "1rem",
                              display: 'flex',
                              alignItems: 'end',
                            }}
                            key={item._id}
                          >
                            <ArtCopy obj={item} type="art" />
                          </div>
                        ))}
                      </Box>
                    </div>
                  </div>
              }
            </div>
          </div >}</>
  );
}

export default ArtistInfo;
