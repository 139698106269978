import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signup, signin, sentOtp, clearStatus, verifyOtp, setNewPassword } from '../../redux/userSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme, useMediaQuery, IconButton } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingSpinner from '../../components/circularProgress/LoadingSpinner';
import './style.css'
import { Visibility, VisibilityOff } from '@mui/icons-material';

const AuthForm = () => {
    const dispatch = useDispatch();
    const { type } = useParams();
    const navigate = useNavigate();

    const recaptchaRef = useRef(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const sitekey = process.env.REACT_APP_SITEKEY1;

    const status = useSelector((state) => state.user.status)
    const message = useSelector((state) => state.user.message)

    const user = useSelector((state) => state.user.user)

    // const [captchaToken, setCaptchaToken] = useState(null);

    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const [userSteps, setUserSteps] = useState("one");

    // const submitButton = (token) => {
    //     setCaptchaToken(token);
    // };

    useEffect(() => {
        if (status === 'succeeded' || status === 'failed') {
            setTimeout(() => dispatch(clearStatus()), 3000);
            if (status === 'succeeded' && (type === 'signup' || type === 'forgotpassword') && userSteps === 'one') {
                setShowOtp(true)
                setFormData({
                    ...formData,
                    "password": "",
                });
                setUserSteps('two')
            }
            if (status === 'succeeded' && (type === 'signup' || type === 'forgotpassword') && userSteps === 'two') {
                setShowOtp(false)
                setUserSteps('three')
                setFormData({
                    ...formData,
                    "password": "",
                });
            }
            if (status === 'succeeded' && (((type === 'signup' || type === 'forgotpassword') && userSteps === 'three') || type === 'signin')) {
                navigate('/');
            }
            if (status === 'failed' && type === 'signin') {
                setShowForgotPassword(true)
                setFormData({
                    ...formData,
                    "password": "",
                });
            }
        }
    }, [status])

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible((prevPasswordVisible) => !prevPasswordVisible);
    };

    const switchMode = () => {
        if (type === "signup") {
            navigate('/user/auth/signin');
        } else {
            navigate('/user/auth/signup');
        }
    }

    const [showOtp, setShowOtp] = useState((type === "forgotpassword" || type === 'signup') && userSteps === 'two' ? true : false)

    useEffect(() => {
        if ((type === 'signup' || type === 'forgotpassword') && userSteps === 'two') {
            setShowOtp(true)
        } else {
            setShowOtp(false)
        }
    }, [type])


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (type === 'signup') {
            if (userSteps === 'one') {
                dispatch(sentOtp({ name: formData.name, email: formData.email, token: '' }))
            } else if (userSteps === 'two') {
                dispatch(verifyOtp({ name: formData.name, email: formData.email, otp: formData.password }))
            } else {
                dispatch(signup(formData));
            }
        } else if (type === 'signin') {
            dispatch(signin(formData));
        } else if (type === 'forgotpassword') {
            if (userSteps === 'one') {
                dispatch(sentOtp({ name: '', email: formData.email, token: '' }))
            }
            else if (userSteps === 'two') {
                dispatch(verifyOtp({ name: '', email: formData.email, otp: formData.password }))
            } else {
                dispatch(setNewPassword({ email: formData.email, password: formData.password }));
            }
        }
    };

    const handleForgotPassword = () => {
        navigate('/user/auth/forgotpassword');
    }

    return (
        <div style={{ display: 'flex', width: '100%', gap: '1rem', flexDirection: 'column' }}>
            {/* <div className='title' style={{ marginLeft: isMobile ? '45%' : '48%' }}>
                {type === 'signup' ? 'Sign Up' : type === 'signin' ? 'Sign In' : 'Forgot Password'}
            </div> */}
            <div
                style={{
                    display: "flex",
                    justifyContent: 'center',
                    padding: "2rem",
                    paddingInline: isMobile ? '' : '10rem',
                    alignItems: 'center'
                }}
            >
                <div style={{ width: isMobile ? '80vw' : '30vw', }}>
                    <form onSubmit={handleSubmit} >

                        {type === 'signup' &&
                            <div className="form-group">
                                <div style={{ marginBottom: '0.5rem' }}>Name:</div>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='Name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>}
                        <div className="form-group"  >
                            <div className='textBody' style={{ marginBottom: '0.5rem' }} >Email:</div>
                            <input
                                className='textBody'
                                type="email"
                                id="email"
                                name="email"
                                placeholder='Enter email'
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        {(showOtp || type === 'signin' || ((type === 'signup' || type === 'forgotpassword') && userSteps === 'three')) &&
                            <div className="form-group">
                                <div className="password-container" style={{ width: "100%", marginBottom: '0.5rem' }}>
                                    <div className='textBody'>{showOtp ? "OTP:" : "Password:"}</div>
                                    <div
                                        className="textBody"
                                        style={{
                                            position: 'relative', width: '100%',
                                            width: "100%",
                                            marginTop: "0.5rem",
                                        }}
                                    >
                                        <style>
                                            {`
                                                input::-ms-reveal {
                                                    display: none;
                                                }
                                                `}
                                        </style>

                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            id="password"
                                            className="password-input"
                                            placeholder="Enter password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                        />
                                        <IconButton
                                            onClick={togglePasswordVisibility}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                padding: 0,
                                            }}
                                        >
                                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>

                                    </div>
                                </div>
                            </div>
                        }

                        <div className="uploadContainer" style={{ display: "flex", width: '100%', }}>

                            <button className='textBody' style={{ width: '7rem', backgroundColor: '#505050' }}>
                                {showOtp ? "Submit" : type === "signup" ? "Sign up" : "Sign in"}
                            </button>
                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                                {status === 'loading' ?
                                    <div style={{ width: '100%', display: 'flex', outline: "none", fontFamily: 'lobster', fontSize: '1.2rem', justifyContent: 'center', alignItems: "center" }}>
                                        <div className="dot-container">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </div> :
                                    status !== 'idle' ?
                                        <div className='textBody' style={{ padding: '0.2rem', textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>
                                            {message}
                                        </div> : <></>
                                }
                            </div>
                        </div>
                    </form>
                    <div style={{ marginTop: '1rem' }}>
                        {type === "signin" && showForgotPassword && <div onClick={handleForgotPassword} style={{ marginBottom: '1rem', textDecoration: 'underline' }}>forgot password</div>}
                        {type === "signin" && <><div className='title' style={{ fontWeight: 'bold' }}>New to our website?</div>
                            <p className='textBody'>Discover various art pieces by creating an account today!</p></>}
                        <div className='textBody' style={{ cursor: 'pointer' }} onClick={switchMode}>
                            {type === "signup"
                                ? "Already have an account? Sign In"
                                : "Don't have an account? Sign Up"}
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
};

export default AuthForm;
