import React, { useEffect, useState } from "react";
import ArtsCopy from "../../components/arts/ArtsCopy";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNonFeatureArtsAsync,
  setAllArts, setPage
} from "../../redux/ArtSlice";
import { useMediaQuery, useTheme, Typography, Box, CardMedia } from "@mui/material";
import CenteredCircularProgress from "../../components/circularProgress/CenteredCircularProgress";

function ArtsPage() {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const featuredArts = useSelector((state) => state.art.featuredArts);
  const nonFeaturedArts = useSelector((state) => state.art.nonFeaturedArts);
  const showAllArts = useSelector((state) => state.art.allArts)
  const arts = useSelector((state) => state.art.arts)
  const hasMore = useSelector((state) => state.art.hasMore);

  const [isLoading, setLoading] = useState(false)

  // const [arts, setArts] = useState([]);
  const page = useSelector((state) => state.art.page);
  // const [isLoading, setLoading] = useState(false);

  const [filteredArts, setFilteredArts] = useState(arts);
  useEffect(() => {
    const fetchArts = async () => {
      setLoading(true);
      try {
        await dispatch(fetchNonFeatureArtsAsync(page));
      } catch (error) {
        console.error('Failed to fetch arts:', error);
      } finally {
        setLoading(false);
      }
    };
    if (hasMore) {
      fetchArts();
    }
  }, [page, hasMore, dispatch]);

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 50 >= document.documentElement.scrollHeight && !isLoading && hasMore) {
      dispatch(setPage());
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, hasMore]);

  const [choice, setChoice] = useState('');

  // const [showAllArts, setShowAllArts] = useState(false);

  // useEffect(() => {
  //   if (nonFeaturedArts.length === 0) {
  //     dispatch(fetchNonFeatureArtsAsync());
  //   }
  // }, [dispatch, nonFeaturedArts]);

  const handleArtFilterToggle = (setOption) => {
    let updatedArts;

    if (setOption === 'Available') {
      setChoice('Available')
      updatedArts = arts.filter(art => art.statuss === 'Available');
    } else if (setOption === 'Sold') {
      setChoice('Sold')
      updatedArts = arts.filter(art => art.statuss === 'Sold');
    } else if (setOption === 'Ascending') {
      setChoice('Ascending')
      updatedArts = [...arts].sort((a, b) => a.artName.localeCompare(b.artName));
    } else if (setOption === 'Descending') {
      setChoice('Descending')
      updatedArts = [...arts].sort((a, b) => b.artName.localeCompare(a.artName));
    }

    setFilteredArts(updatedArts);
  };

  useEffect(() => {
    // Set the default state when arts change
    setFilteredArts(arts);
  }, [arts]);


  return (
    <>
      <ArtsCopy
        name={choice}
        data={filteredArts}
        type="art"
        onArtFilterToggle={handleArtFilterToggle}
        showAllArts={showAllArts}
        isLoading={isLoading}
      />
      {/* {isLoading &&
        <div style={{ width: '100%', display: 'flex', outline: "none", fontFamily: 'lobster', fontSize: '1.2rem', justifyContent: 'center', alignItems: "center" }}>
          <div className="dot-container">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>} */}
    </>
  );
}

export default ArtsPage;
