import React, { useState, useEffect, useRef } from "react";
import {
  IconButton,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Collapse,
  Grow
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import axios from "axios";
import "./navBar.css"; // Import CSS file
import { useLocation, useNavigate } from "react-router-dom";
import { searchWord, setSearchWord } from "../../redux/SearchSlice";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import appLogo from '../../assets/appLogo.png'
import '../../global.css';

function NavBar({ setShowSideBar }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const userToken = useSelector((state) => state.user.jwt)
  const [message, setMessage] = useState("");
  const [searchButton, setSearchButton] = useState(true);
  const searchRef = useRef(null);
  const location = useLocation();
  const { pathname, search, hash } = location;

  const dispatch = useDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isProTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [searchQuery, setSearchQuery] = useState('');

  const PORT = process.env.REACT_APP_API_BASE_URL;

  const handlechange = () => {
    setSearchButton((prevSearchButton) => !prevSearchButton);
  };

  const handleClickUser = () => {
    if (userToken) {
      navigate('/user/profile')
    } else {
      navigate('/user/auth/signin')
    }
  }

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchButton(true);
    }
  };

  useEffect(() => {
    if (!searchButton) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      setSearchQuery('')
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchButton]);

  useEffect(() => {
    axios
      .get(PORT)
      .then((response) => {
        setMessage(response.data);
      })
      .catch((error) => {
        setMessage("There is an error!", error);
      });
  }, []);



  // Handle change in input field
  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = async () => {
    dispatch(searchWord({ searchData: searchQuery }))
    dispatch(setSearchWord(searchQuery))
    navigate("/search");
  };

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutsideMenu = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, []);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignContent: 'center', alignItems: 'center', padding: '0.2rem', paddingInline: isMobile ? '' : '1rem'
      height: '6rem'
    }} >
      <div style={{
        display: 'flex',
        flex: 1,
        textAlign: 'center',
        justifyContent: 'left',
        // padding: '10px',
        // border: 'solid'
      }}>
        {isMobile || isTablet || isProTablet ? <IconButton
          variant="outlined"
          onClick={() => setShowSideBar((prev) => !prev)}
          style={{ marginLeft: '1rem' }}
        >
          <MenuIcon />
        </IconButton>
          : <></>}
      </div>
      <div style={{
        flex: 'none', // Prevent the center div from resizing
        //  width: '200px', // Fixed width for the center item
        textAlign: 'center',

        // marginLeft: isMobile ? searchButton ? '35%' : '20%' : isLargeScreen ? '44%' : '46%',
        cursor: 'pointer',
      }} onClick={() => {
        navigate("/");
      }} >
        {/* <div style={{ justifyContent: 'center', display: 'flex', fontFamily: 'Gotham', fontWeight: 'bold', fontSize: isMobile ? '1.2rem' : '1.3rem' }}>
          DAWANI
        </div>
        <div style={{ fontFamily: 'Gotham', fontSize: isMobile ? '1rem' : '1.1rem' }}>
          ART GALLERY
        </div> */}
        {/* {isMobile ?
          (searchButton ?
            <img
              height='50rem'
              // width='100rem'
              style={{ marginTop: '1rem' }}
              src={appLogo}
              alt={`Slide`}
            />
            : (
              <div
                style={{ paddingInlineEnd: '1rem', alignContent: 'center', alignItems: 'center' }}
                ref={searchRef}
              // className={`search-input-container ${isMobile ? "search-input-container-mobile" : ""}`}
              >
                <Grow in={!searchButton} timeout={500}>
                  <TextField
                    variant="standard"
                    placeholder="Search..."
                    size="small"
                    // label="Search"
                    id="Search"
                    style={{ width: '10rem' }}
                    fullWidth={isMobile}
                    value={searchQuery}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      style: {
                        border: 'none',
                        outline: 'none',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                      },
                    }}
                  />
                </Grow>
                <CloseIcon style={{}} className="search-icon" onClick={handlechange} />
              </div>
            )) : */}
        {isMobile ?
          <img
            height='65rem'
            // width='100rem'
            style={{
              marginTop: '1rem',
              opacity: searchButton ? 1 : 0,
              transition: 'opacity 0.5s ease-out',
              position: 'absolute', // Ensure the image stays in place
              left: '50%', top: '0.5%',          // Center horizontally
              transform: 'translateX(-50%)',
            }}
            src={appLogo}
            alt={`Slide`}
          /> :
          <img
            height='85rem'
            // width='100rem'
            style={{ marginTop: '1rem' }}
            src={appLogo}
            alt={`Slide`}
          />}
      </div>
      {isMobile || isTablet || isProTablet ?
        <div style={{ display: 'flex', flex: 1, justifyContent: 'end', width: isMobile ? '15rem' : isTablet ? '10rem' : '15rem' }}>
          <div style={{ display: 'flex', justifyContent: 'end', marginRight: '1rem', flex: 1 }}>
            {searchButton ? (
              <div style={{}}><SearchIcon className="search-icon" onClick={handlechange} /></div>
            ) : (
              <div
                style={{ display: 'flex', flexDirection: 'row' }}
                ref={searchRef}
              // className={`search-input-container ${isMobile ? "search-input-container-mobile" : ""}`}
              >
                <Grow in={!searchButton} timeout={500}>
                  <TextField
                    variant="standard"
                    placeholder="Search..."
                    size="small"
                    // label="Search"
                    id="Search"
                    style={{ width: isMobile ? '15rem' : isTablet ? '10rem' : '15rem' }}
                    fullWidth={isMobile}
                    value={searchQuery}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      style: {
                        border: 'none',
                        outline: 'none',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                      },
                    }}
                  />
                </Grow>
                <CloseIcon style={{}} className="search-icon" onClick={handlechange} />
              </div>
            )}
          </div>
          {/* <div ref={dropdownRef} style={{ position: 'relative', display: 'inline-block', marginTop: '0.25rem', marginRight: '1.8rem' }}>
            {isMobile && searchButton && <>
              <div onClick={toggleDropdown} className="title" style={buttonStyle}>
                MENU
              </div>
              {isOpen && (
                <ul style={menuStyle}>
                  <li style={itemStyle}>
                    <div onClick={() => {
                      navigate("/arts");
                      setIsOpen(false);
                      setShowSideBar(false);
                    }} className="title" style={{ paddingInlineEnd: '1rem', cursor: 'pointer', color: pathname === '/arts' ? '#770000' : '' }}>ART</div>
                  </li>
                  <li style={itemStyle}>
                    <div onClick={() => {
                      navigate("/artists");
                      setIsOpen(false);
                      setShowSideBar(false);
                    }} className="title" style={{ paddingInlineEnd: '1rem', cursor: 'pointer', color: pathname === '/artists' ? '#770000' : '' }}>ARTIST</div>
                  </li>
                  <li style={itemStyle}>
                    <div onClick={() => {
                      navigate("/about");
                      setIsOpen(false);
                      setShowSideBar(false);
                    }} className="title" style={{ paddingInlineEnd: '1rem', cursor: 'pointer', color: pathname === '/about' ? '#770000' : '' }}>ABOUT</div>
                  </li>
                  <li style={itemStyle}>
                    <div onClick={() => {
                      handleClickUser();
                      setIsOpen(false);
                      setShowSideBar(false);
                    }} className="title" style={{ paddingInlineEnd: '1rem', cursor: 'pointer', color: pathname === '/user/profile' ? '#770000' : '' }}>PROFILE</div>
                  </li>

                  {/* <li style={itemStyle}>Item 4</li>
              <li style={itemStyle}>Item 5</li> 
        </ul>
              )}</>}
          </div > */}
        </div >
        :
        <div style={{
          display: 'flex',
          flex: 1,
          textAlign: 'center',
          // padding: '30px', 
          flexDirection: 'row', justifyContent: 'end'
        }}>
          {searchButton ? (
            <div style={{ paddingInlineEnd: '1rem', alignContent: 'center', alignItems: 'center' }}><SearchIcon className="search-icon" onClick={handlechange} /></div>
          ) : (
            <div
              style={{ paddingInlineEnd: '1rem', alignContent: 'center', alignItems: 'center' }}
              ref={searchRef}
            // className={`search-input-container ${isMobile ? "search-input-container-mobile" : ""}`}
            >
              <Grow in={!searchButton} timeout={500}>
                <TextField
                  variant="standard"
                  placeholder="Search..."
                  size="small"
                  // label="Search"
                  id="Search"
                  style={{ width: '10rem' }}
                  fullWidth={isMobile}
                  value={searchQuery}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      border: 'none',
                      outline: 'none',
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                    },
                  }}
                />
              </Grow>
              <CloseIcon style={{}} className="search-icon" onClick={handlechange} />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row', fontSize: isMobile ? '1.1rem' : '1.2rem', alignContent: 'center', alignItems: 'center' }}>
            <div onClick={() => {
              navigate("/art");
              setShowSideBar(false);
            }} className="title" style={{ paddingInlineEnd: '1rem', cursor: 'pointer', color: pathname === '/art' ? '#770000' : '' }}>ART</div>
            <div onClick={() => {
              navigate("/artist");
              setShowSideBar(false);
            }} className="title" style={{ paddingInlineEnd: '1rem', cursor: 'pointer', color: pathname === '/artist' ? '#770000' : '' }}>ARTIST</div>
            {!isMobile && <div onClick={() => {
              navigate("/about");
              setShowSideBar(false);
            }} className="title" style={{ paddingInlineEnd: '1rem', cursor: 'pointer', color: pathname === '/about' ? '#770000' : '' }}>ABOUT</div>}
            <PersonOutlineIcon style={{ paddingInlineEnd: '1rem', cursor: 'pointer', color: pathname === '/user/profile' ? '#77000' : '' }} className="search-icon" onClick={handleClickUser} />
          </div>
        </div>}
    </div >
  );
}

const buttonStyle = {
  // paddingBottom: '1rem',
  // marginRight: '2rem',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  color: 'black',
  paddingLeft: '0.5rem',
  // paddingBottom: '1rem',
  // border: '1px solid #ccc',
};

const menuStyle = {
  listStyleType: 'none',
  // marginRight: '2rem',
  // marginRight: '2rem',
  // border: 'solid',
  // paddingLeft: '0.5rem',
  padding: '0',
  margin: '0',
  marginTop: '1rem',
  position: 'absolute',
  top: '100%',
  // left: '5',
  zIndex: '10',
  backgroundColor: 'white', // No background color
};

const itemStyle = {
  // paddingTop: '1rem',
  paddingLeft: '0.5rem',
  paddingBottom: '0.5rem',
  cursor: 'pointer',
};

export default NavBar;
