import React from 'react'
import { useMediaQuery, useTheme } from "@mui/material";
import '../../global.css';

function Privacy() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div style={{ padding: '1rem', paddingInline: isMobile ? '' : '10rem', textAlign: 'justify' }}>
            <div className='title' style={{ textAlign: 'center', marginBottom: '1rem' }}>Privacy Policy</div>
            <div className='textBody' style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div>
                    <div>1. GENERAL</div>
                    <div style={{ padding: '1rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>1.1</div><div>DAWANI ART GALLERY is committed to the protection of personal information provided by Users on its website <a href="https://www.dawaniartgallery.com" style={{ color: 'black', textDecoration: 'none' }} target="_blank">www.dawaniartgallery.com</a> ("Website"). Users agree that use of the Website implies consent to the collection, retention, and use of User's personal information in accordance with the terms of this Privacy Policy (“Privacy Policy”).</div></div>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>1.2</div><div>Dawani Art Gallery takes the privacy of Users seriously and is committed to safeguarding the privacy of Users while providing Services in a personalized and valuable manner.</div></div>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>1.3</div><div>No User information is rented or sold by Dawani Art Gallery to any third party. When a User uses the Services, Users’ IP addresses and other personal information may automatically get stored. A high standard of security is maintained by Dawani Art Gallery for Users. However, the transmission of information via the internet and web networks is not completely secure. While Dawani Art Gallery does its best to protect Users' information, particularly with respect to protection of Users’ personal data, Dawani Art Gallery cannot ensure the security of Users’ data transmitted via the internet, web network, or any other networks.</div></div>
                    </div>
                </div>
                <div>
                    <div>2. INFORMATION COLLECTED</div>
                    <div style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                        <div>2.1 Traffic Data Collected</div>
                        <div style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                            <div>In order to provide Services, Dawani Art Gallery automatically tracks and collects the following categories of information when a User uses the Services:</div>
                            <div style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                                <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(a)</div><div> IP addresses;</div></div>
                                <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(b)</div><div> Domain servers; and</div></div>
                                <div style={{ display: 'flex', justifyContent: 'row' }}><div style={{ marginRight: '0.5rem' }}>(c)</div><div> Other information associated with the interaction of User's device and the Services (collectively "Traffic Data").</div></div>
                            </div>
                        </div>

                        <div style={{ paddingTop: '1rem' }}>2.2 Personal Information Collected</div>
                        <div style={{ padding: '1rem' }}>
                            <div>In order to provide Services, Dawani Art Gallery may require the User to provide certain information that personally identifies such User ("Personal Information").</div>
                            <div>Personal Information includes the following categories of information:</div>
                            <div style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                                <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(a)</div><div> Contact data (such as name, e-mail address, phone number, and any extra contact details); and</div></div>
                                <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(b)</div><div> Demographic data (such as time zone, postal address, and location details).</div></div>
                            </div>
                            <div>If a User communicates with Dawani Art Gallery by, for example, e-mail or letter, any information provided in such communication may be retained by Dawani Art Gallery.</div>
                        </div>

                        <div>2.3 Information Collected from Third Parties</div>
                        <div style={{ padding: '1rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(a)</div><div> Information obtained from other sources: Dawani Art Gallery may receive information about the User from other online sources. Dawani Art Gallery may add it to its account information system and treat it in accordance with this Privacy Policy. Dawani Art Gallery may obtain updated contact information(s) from third parties in order to correct its records, fulfill the Services, or communicate with the User.</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(b)</div><div> Demographic and other information: Dawani Art Gallery may reference other sources of demographic and other information to provide the User with more targeted communications and promotions. Dawani Art Gallery uses various third-party analytics service providers, among others, to track User behavior on our Website. They are used to support display advertising towards helping Dawani Art Gallery gain understanding of the Users' demographics and interests. The reports are anonymous and cannot be associated with any individual personally identifiable information that the User may have shared with Dawani Art Gallery.</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(c)</div><div>Transactional and Billing Information: When the User makes any transaction on the Website, Dawani Art Gallery’s payment gateway provider may collect the User’s personal information such as address or billing information, including credit/debit card number and expiration date, etc. This information is stored by the payment gateway partner, and such payment gateway partners are not controlled by Dawani Art Gallery. The User visits such payment gateways at its own risk. Dawani Art Gallery encourages the User to familiarize itself with the privacy statements provided by such payment gateways prior to providing them with information or entering into any transactions with them. Dawani Art Gallery does not assume any responsibility or liability for such payment gateways, the content of such payment gateways, and their privacy practices, nor does it endorse them. Dawani Art Gallery may receive financial or transactional information (for example, transaction ID, order ID, etc.) from third-party vendors in case of payments, for reconciliation and validation of transactions, and also for the purpose of resolutions of complaints (in case of failed transactions and refunds).</div></div>
                        </div>

                        <div>2.4 Children's Privacy</div>
                        <div style={{ padding: '1rem' }}>The Website is a general audience website, and it and its related sites, applications, services, and tools are not intended for children under the age of 18. Dawani Art Gallery does not knowingly collect personal information from children under 13. If Dawani Art Gallery becomes aware that a child under 13 has provided personal data, Dawani Art Gallery will take steps to delete such information.</div>

                        <div>2.5 Links to Third-Party Websites</div>
                        <div style={{ padding: '1rem' }}>The Website may contain links to third-party websites. Users agree and understand that privacy policies of these third-party websites are not under the control of Dawani Art Gallery. Users understand that once a User leaves Dawani Art Gallery's servers, use of any information such User provides will be governed by the privacy policy of the operator of the site used by such User. In addition, the Website may occasionally contain links to Third-Party Sites. If you click on the links to Third-Party Websites, you leave the Website. We are not responsible for the content of these Third-Party Websites or for the security of your personal information when you use the Third-Party Websites. These third-party service providers and Third-Party Sites may have their own privacy policies governing the storage and retention of your personal information that you may be subject to. They may collect information such as your IP address, browser specification, or operating system. This Privacy Policy does not govern personal information provided to, stored on, or used by these third-party providers and Third-Party Sites. We recommend that when you enter a Third-Party Site, you review the Third-Party Site's privacy policy as it relates to safeguarding your personal information. We use third-party advertising companies to serve ads when you visit the Website. These companies may use information (not including your name, address, email address, or telephone number) about your visits to the Website and Third-Party Websites in order to provide advertisements about goods and services of interest to you.</div>

                        <div>2.6 Use of Cookies</div>
                        <div style={{ padding: '1rem' }}>We use data collection devices such as “cookies” on certain pages of the Website to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a "cookie". We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case, you may not be able to use certain features on the Site, and you may be required to reenter your password more frequently during a session. Additionally, you may encounter "cookies" or other similar devices on certain pages of the Site that are placed by third parties. For example, if you view a web page created by a user, there may be a "cookie" placed within that web page. We do not control the use of cookies by third parties.</div>
                    </div>
                </div>
                <div>
                    <div>3. DISCLOSURE OF PERSONAL INFORMATION</div>
                    <div style={{ padding: '1rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>3.1</div><div>  Dawani Art Gallery does not disclose User’s Personal Information to any third parties other than to Dawani Art Gallery’s officers, employees, agents, consultants, and affiliates.</div></div>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>3.2</div><div> Dawani Art Gallery uses its best efforts to use Users’ information in aggregate form (so that no individual user is identified) for the following purposes:</div></div>
                        <div style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(a)</div><div>  To build up marketing profiles;</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(b)</div><div>  To aid strategic development, data collection, and business analytics;</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(c)</div><div>  To manage relationships with advertisers and partners;</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(d)</div><div> To audit usage of Services; and/or</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(e)</div><div>  To enhance User’s experience in relation to provision of Services (“collectively, “Permitted Use”).</div></div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'row', }}><div style={{ marginRight: '0.5rem' }}>3.3</div><div> Dawani Art Gallery reserves the right to disclose Personal Information if required to do so by law or if Dawani Art Gallery believes that it is necessary to do so to protect and defend the rights, property, and/or personal safety of Dawani Art Gallery, the Services, or other Users.</div></div>
                    </div>
                </div>
                <div>
                    <div>4. CONFIDENTIALITY AND SECURITY</div>
                    <div style={{ padding: '1rem' }}>
                        <div>Except as otherwise provided in this Privacy Policy, Dawani Art Gallery will keep all Personal Information private and will not share it with third parties unless Dawani Art Gallery believes in good faith that disclosure of such Personal Information or any other information Dawani Art Gallery collects about Users is necessary for Permitted Use or to:</div>
                        <div style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(a)</div><div> Comply with a court order or other legal process;</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(b)</div><div>Protect the rights, property, or safety of Dawani Art Gallery or another party;</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>(c)</div><div> Enforce the Agreement, including Terms; or</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', }}><div style={{ marginRight: '0.5rem' }}>(d)</div><div>Respond to claims that any posting or other content violates the rights of third parties.</div></div>
                        </div>
                    </div>
                </div>

                <div>
                    <div> 5. SECURITY</div>
                    <div style={{ padding: '1rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>5.1</div><div>The security of Users’ Personal Information is important to Dawani Art Gallery. Dawani Art Gallery follows generally accepted industry standards to protect the Personal Information submitted to Dawani Art Gallery, both during transmission and once Dawani Art Gallery receives it.</div></div>
                        <div style={{ display: 'flex', justifyContent: 'row', }}><div style={{ marginRight: '0.5rem' }}>5.2</div><div> Although Dawani Art Gallery makes best possible efforts to store Personal Information in a secure operating environment that is not open to the public, Users should understand that there is no such thing as complete security, and Dawani Art Gallery does not guarantee that there will be no unintended disclosures of Personal Information. If Dawani Art Gallery becomes aware that certain Personal Information has been disclosed in a manner not in accordance with this Privacy Policy, Dawani Art Gallery will use reasonable efforts to notify the concerned User of the nature and extent of such disclosure (to the extent Dawani Art Gallery is aware of that information) as soon as reasonably possible and as permitted by law.</div></div>
                    </div>
                </div>
                <div>
                    <div>6. UPDATES AND CHANGES TO PRIVACY POLICY</div>
                    <div style={{ padding: '1rem' }}>Dawani Art Gallery reserves the right, at any time, to add to, change, update, or modify this Privacy Policy. Users are requested to review this Privacy Policy frequently. If any change has been incorporated in this Privacy Policy by Dawani Art Gallery, then Dawani Art Gallery will post such changes on this page. In all cases, use of information Dawani Art Gallery collects is subject to the Privacy Policy in effect at the time such information is collected by Dawani Art Gallery.</div>
                </div>
                <div>
                    <div>7. USERS’ RIGHTS</div>
                    <div style={{ padding: '1rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>7.1</div><div> Users have a right to correct any errors in such User’s Personal Information available with Dawani Art Gallery. A User may request Dawani Art Gallery in writing that Dawani Art Gallery ceases to use such User’s Personal Information. Dawani Art Gallery may stop providing Services to such a User, if so required.</div></div>
                        <div style={{ display: 'flex', justifyContent: 'row', }}><div style={{ marginRight: '0.5rem' }}>7.2</div><div> For withdrawing its consent at any time with Dawani Art Gallery, the User may choose to do so by sending Dawani Art Gallery an e-mail. Dawani Art Gallery shall review such request and may ask the User to verify its identity. Post verification, Dawani Art Gallery will withdraw the consent for which the request was made by the User and stop any further processing of its Personal Information.</div></div>
                    </div>
                </div>
                <div>
                    <div>8. USERS’ DATA PROTECTION RIGHTS</div>
                    <div style={{ padding: '1rem' }}>
                        <div>Dawani Art Gallery would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</div>
                        <div style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>8.1</div><div> The right to access - You have the right to request Dawani Art Gallery for copies of your personal data. We may charge you a small fee for this service.</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>8.2</div><div> The right to rectification - You have the right to request that Dawani Art Gallery correct any information you believe is inaccurate. You also have the right to request Our Company to complete information you believe is incomplete.</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>8.3</div><div> The right to erase - You have the right to request that Dawani Art Gallery erase your personal data, under certain conditions.</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>8.4</div><div> The right to restrict processing - You have the right to request that Dawani Art Gallery restrict the processing of your personal data, under certain conditions.</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>8.5</div><div> The right to object to processing - You have the right to object to Dawani Art Gallery’s processing of your personal data, under certain conditions.</div></div>
                            <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>8.6</div><div> The right to data portability - You have the right to request that Dawani Art Gallery transfer the data that we have collected to another organization, or directly to you, under certain conditions.</div></div>
                        </div>
                        <div>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: support@Dawaniartgallery.com</div>
                    </div>.
                </div>
                <div><div>9. COMPLAINTS</div>
                    <div style={{ padding: '1rem' }}>The User can write to Dawani Art Gallery in case of any grievance or complaint at the support e-mail address. If the User has any complaints regarding processing of its Personal Information, the User may contact the officer at the below-mentioned e-mail address: support@dawaniartgallery.com.
                    </div>
                </div>
                <div><div>10. RESTRICTION OF LIABILITY</div>
                    <div style={{ padding: '1rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>10.1</div><div>  No warranty of any kind, implied, expressed or statutory, including but not limited to the warranties of non-infringement of third party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given with respect to the Services provided by Dawani Art Gallery, including provision of Website.</div></div>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>10.2</div><div>  Any reference on the Website to any specific commercial products, processes, or services, or the use of any trade, firm or corporation name is for the information and convenience of the public, and does not constitute endorsement, recommendation, or favoring by Dawani Art Gallery.</div></div>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>10.3</div><div>  Services provided by Dawani Art Gallery only include provision of an online platform for facilitating buying and selling of Art Work and promotion of art, unless otherwise has expressly been provided in the Terms.</div></div>
                    </div>
                </div>
                <div>
                    <div> 11. SUPPORT</div>
                    <div style={{ padding: '1rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'row', marginBottom: '0.5rem' }}><div style={{ marginRight: '0.5rem' }}>11.1</div><div>  If the User requires any information or clarification regarding the use of its Personal Information or this Privacy Policy, or has grievances with respect to use of its Personal Information, please email Dawani Art Gallery at support@dawaniartgallery.com.</div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy