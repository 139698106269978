import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import "./AddArt";
import AddArt from "./AddArt";
import AddArtist from "../addArtist/AddArtist";
import { useLocation } from "react-router-dom";
import "./addArtValidation.css";

function AddArtValidation() {
  const location = useLocation();
  const { type } = location.state || 'art';

  const [content, setContent] = useState("");
  const [form, setform] = useState(type === 'artist' ? true : false);

  useEffect(() => {
    const encryptedPassword = localStorage.getItem("encryptedPassword");
    if (!encryptedPassword) {
      setContent("Not authenticated to access this page.");
      return;
    }

    const secretKey = process.env.REACT_APP_VALID_PASSWORD; // Same secret key used for encryption
    //   try {
    //     const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    //     const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
    //     const envPassword = process.env.REACT_APP_MYNAME;
    //     // console.log(decryptedPassword);
    //     // console.log(envPassword);
    //     if (form === false) {
    //       if (decryptedPassword === envPassword) {
    //         setContent(<AddArt />);
    //       } else {
    //         setContent("Not authenticated to access this page.");
    //       }
    //     } else {
    //       if (decryptedPassword === envPassword) {
    //         setContent(<AddArtist />);
    //       } else {
    //         setContent("Not authenticated to access this page.");
    //       }
    //     }
    //   } catch (e) {
    //     setContent("Not authenticated to access this page.");
    //   }
    // }, [form]);
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
      const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);
      const envPassword = process.env.REACT_APP_MYNAME;
      // console.log(decryptedPassword);
      // console.log(envPassword);

      if (decryptedPassword === envPassword) {
        if (form === false) {
          setContent(<AddArt />);
        } else {
          setContent(<AddArtist />);
        }
      } else {
        setContent("Not authenticated to access this page.");
      }
    } catch (e) {
      setContent("Not authenticated to access this page.");
    }
  }, [form]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem', width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
          <div
            style={{
              width: '10rem', textAlign: 'center', backgroundColor: form ? '#DCDCDC' : '#505050',
              color: form ? '' : 'white', padding: '0.5rem', borderRadius: '0.2rem', cursor: 'pointer'
            }}
            // className={`formSelection ${form === false ? "active" : ""}`}
            onClick={() => {
              setform(false);
            }}
          >
            Add Art
          </div>
          <div
            style={{
              width: '10rem', textAlign: 'center', backgroundColor: form ? '#505050' : '#DCDCDC',
              color: form ? 'white' : '', padding: '0.5rem', borderRadius: '0.2rem', cursor: 'pointer'
            }}
            // className={`formSelection ${form === true ? "active" : ""}`}
            onClick={() => {
              setform(true);
            }}
          >
            Add Artist
          </div>
        </div>
        <div>{content}</div>
      </div>
    </>
  );
}

export default AddArtValidation;
