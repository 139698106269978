import React, { useState, useEffect } from "react";
import "./styles.css";

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsFadingOut(false);
      setIsVisible(true);
    } else {
      if (isVisible) {
        setIsFadingOut(true);
        setTimeout(() => setIsVisible(false), 300); // Match the CSS transition duration
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [isVisible]);

  return (
    <>
      {isVisible && (
        <button
          className={`scroll-to-top ${isFadingOut ? "fade-out" : ""}`}
          onClick={scrollToTop}
        >
          <span className="scroll-to-top-icon">↑</span>
        </button>
      )}
    </>
  );
}

export default ScrollToTop;

// import React, { useState, useEffect } from "react";
// import { Fab } from "@mui/material";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// function ScrollToTop() {
//   const [isVisible, setIsVisible] = useState(false);

//   const toggleVisibility = () => {
//     if (window.scrollY > 300) {
//       setIsVisible(true);
//     } else {
//       setIsVisible(false);
//     }
//   };

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", toggleVisibility);
//     return () => window.removeEventListener("scroll", toggleVisibility);
//   }, []);

//   return (
//     <div>
//       {isVisible && (
//         <Fab
//           color="skyblue"
//           aria-label="scroll to top"
//           onClick={scrollToTop}
//           sx={{ position: "fixed", bottom: "2rem", right: "2rem" }}
//           style={{display:"inline", opacity:0, animation:""}}
//         >
//           <KeyboardArrowUpIcon />
//         </Fab>
//       )}
//     </div>
//   );
// }

// export default ScrollToTop;
