import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const PORT = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: PORT,
});

export const fetchArtistInfo = createAsyncThunk(
  'arts/fetchArtistInfo',
  async (artistId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/artists/fetchArtistInfo/${artistId}`);
      return response.data;
    } catch (error) {
      // Ensure that error.response.data exists
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: 'Unexpected error occurred.' });
      }
    }
  }
);

// Define an async thunk for adding art
export const addArtistAsync = createAsyncThunk(
  "arts/addArtistAsync",
  async (artist, { rejectWithValue }) => {
    try {
      const response = await api.post("/artists/addartist", artist);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateArtistAsync = createAsyncThunk(
  "artists/updateartist",
  async (artist, { rejectWithValue }) => {
    try {
      const response = await api.put(`/artists/updateartist/${artist._id}`, artist);
      // console.log(response.data);
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Define an async thunk for getting art
export const fetchArtistsAsync = createAsyncThunk(
  "arts/fetchArtistsAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/artists");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteArtistAsync = createAsyncThunk(
  "artists/deleteArtistAsync",
  async (artistId, { rejectWithValue }) => {
    // console.log("async thuck: ", artId);
    try {
      const response = await api.delete(`/artists/deleteartist/${artistId}`);
      // console.log(
      //   "return Art ID from backend art for update state of deleted art: ",
      //   artId
      // );
      return artistId; // Return the artId to remove it from the state
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const artistsSlice = createSlice({
  name: "artists",
  initialState: {
    artists: [],
    featureArtist: [],
    status: "idle",
    error: null,
    allArtist: false,
    statusArtistInfo: "idle",
    artistInfo: {},
    message: '',
  },
  reducers: {
    setAllArtists(state) {
      state.allArtist = !state.allArtist;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addArtistAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.feature === "Yes") {
          state.featureArtist.push(action.payload);
        }
        state.artists.push(action.payload);
      })
      .addCase(fetchArtistsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        for (let artist of action.payload) {
          if (artist.feature === "Yes") {
            state.featureArtist.push(artist);
          }
        }
        state.artists = action.payload;
      })
      .addCase(updateArtistAsync.pending, (state) => {
        state.status = "loading";
        // console.log("Status loading");
      })
      .addCase(updateArtistAsync.fulfilled, (state, action) => {

        if (action.payload.feature === "Yes") {
          let index = state.featureArtist.findIndex(
            (artist) => artist._id === action.payload._id
          );
          if (index !== -1) {
            state.featureArtist[index] = action.payload;
          } else {
            state.featureArtist.push(action.payload);
          }
        } else {
          let index = state.featureArtist.findIndex(
            (artist) => artist._id === action.payload._id
          );
          if (index !== -1) {
            state.featureArtist.splice(index, 1)
          }
        }
        let index = state.artists.findIndex(
          (artist) => artist._id === action.payload._id
        );
        if (index !== -1) {
          state.artists[index] = action.payload;
        } else {
          state.artists.push(action.payload);
        }
        state.status = "succeeded";
      })
      .addCase(deleteArtistAsync.pending, (state) => {
        state.status = "loading";
        // console.log("Status loading");
      })
      .addCase(deleteArtistAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.artists = state.artists.filter((artist) => artist._id !== action.payload);
        state.featureArtist = state.featureArtist.filter(
          (artist) => artist._id !== action.payload
        );
      })
      .addCase(fetchArtistInfo.pending, (state) => {
        state.statusArtistInfo = "loading";
      })
      .addCase(fetchArtistInfo.fulfilled, (state, action) => {
        state.statusArtistInfo = "succeeded";
        state.artistInfo = action.payload.artistInfo
      })
      .addCase(fetchArtistInfo.rejected, (state) => {
        state.statusArtistInfo = "failed";
        state.message = "Artist not found.";
      })
  },
});

export const { setAllArtists } = artistsSlice.actions;
export default artistsSlice.reducer;
