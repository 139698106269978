import { TextField, Button, Snackbar, Alert, backdropClasses } from "@mui/material";
import { useEffect, useState } from "react";
import "./addArt.css";
import { useDispatch, useSelector } from "react-redux";
import { addArtAsync, updateArtAsync } from "../../redux/ArtSlice";
import FileBase from "react-file-base64";
import ArtistDropdown from "../artist/ArtistDropdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from '../circularProgress/LoadingSpinner';

function AddArt() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { id } = useParams() || null;

  const { obj, type } = location.state || {};
  const isEditMode = type === 'art' ? !!obj : false;

  const [content, setContent] = useState({
    artName: type === 'art' ? obj?.artName || "" : "",
    artistName: type === 'art' ? obj?.artistName || "" : "",
    selectedFile: type === 'art' ? obj?.selectedFile || "" : "",
    size: type === 'art' ? obj?.size || "" : "",
    year: type === 'art' ? obj?.year || "" : "",
    medium: type === 'art' ? obj?.medium || "" : "",
    statuss: type === 'art' ? obj?.statuss || "Available" : "Available",
    feature: type === 'art' ? obj?.feature || "No" : "No",
    artistId: type === 'art' ? obj?.artistId || "" : "",
  });

  const [fileKey, setFileKey] = useState(Date.now());
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [uploadAttempted, setUploadAttempted] = useState(false);
  const [loading, setLoading] = useState(false)

  const clear = () => {
    setContent({
      ...content,
      artName: "",
      selectedFile: "",
      size: "",
      year: "",
      medium: "",
      statuss: "Available",
      feature: "No",
    });
    setErrors({});
    setFileKey(Date.now());
  };

  const validate = () => {
    const newErrors = {};
    if (!content.artName) newErrors.artName = "Art name is required";
    if (!content.artistName) newErrors.artistName = "Artist name is required";
    if (!content.selectedFile) newErrors.selectedFile = "Image is required";
    return newErrors;
  };

  const updatedContent = {
    ...content,
    _id: obj?._id, // Assuming obj._id exists
    artistId: obj?.artistId,
    artistName: obj?.artistName,
  };

  const handleUpload = (e) => {
    e.preventDefault();


    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0 || content.selectedFile === "") {
      setErrors(validationErrors);
      return;
    }
    setUploadAttempted(true);
    setLoading(true)
    if (isEditMode) {
      dispatch(updateArtAsync(updatedContent));
    } else {
      dispatch(addArtAsync(content));
    }
    clear();
  };

  const handleFeatureChange = (event) => {
    setContent({ ...content, feature: event.target.value });
  };

  const handleStatusChange = (event) => {
    setContent({ ...content, statuss: event.target.value });
  };

  // const [selectedArtist, setSelectedArtist] = useState("");
  const handleArtistSelect = (artist) => {
    // setSelectedArtist(artist);
    setContent({
      ...content,
      artistName: artist.artistName,
      artistId: artist._id,
    });
  };

  const status = useSelector((state) => state.art.status);
  const artists = useSelector((state) => state.artist.artists);
  const updatedArt = useSelector((state) =>
    state.art.arts.find((art) => art._id === obj?._id)
  );

  useEffect(() => {
    if (uploadAttempted && (!isEditMode || updatedArt) && status === "succeeded") {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
      if (isEditMode && updatedArt) {
        navigate(`/art/artinfo/${updatedArt._id}`, {
          state: { obj: updatedArt, type: "art" },
        });
      }
      setLoading(false)
    }
  }, [status, uploadAttempted, updatedArt, navigate]);

  return (
    <div className="mArt" style={{ width: '100%' }}>
      <form onSubmit={handleUpload} style={{ width: '25rem' }}>
        <div className="container">
          <div className="artName">
            <div
              // className="art"
              style={{
                marginBottom: "0rem",
              }}
            >
              Art Name:
            </div>
            <TextField
              sx={{
                marginBottom: "0rem",
              }}
              size="small"
              // label="Art Name"
              id="addArt"
              value={content.artName}
              onChange={(e) =>
                setContent({ ...content, artName: e.target.value })
              }
              required
              error={!!errors.artName}
              helperText={errors.artName}
            />
          </div>

          <div
            className="artistName"
            style={{
              marginBottom: "0rem",
            }}
          >
            <div
              // className="art"
              style={{
                marginBottom: "0rem",
              }}
            >
              Artist Name: {isEditMode ? obj.artistName : null}
            </div>
            {!isEditMode ? (
              <ArtistDropdown
                artists={artists}
                onSelect={handleArtistSelect}
                isEditMode={isEditMode}
              />
            ) : null}
          </div>


          <div className="artName">
            <div
              // className="image"
              style={{
                marginBottom: "0rem",
              }}
            >
              Choose Image:{" "}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                maxWidth: "100%",
              }}
            >
              <FileBase
                key={fileKey}
                type="file"
                size="small"
                multiple={false}
                onDone={({ base64 }) =>
                  setContent({ ...content, selectedFile: base64 })
                }
                required
              />
            </div>
            {errors.selectedFile && (
              <div style={{ color: "red", fontSize: "1rem" }}>
                {errors.selectedFile}
              </div>
            )}
          </div>
          {isEditMode && (
            <img
              style={{
                width: "100%",
                height: "20rem",
                objectFit: 'contain',
              }}
              src={obj.selectedFile}
              alt={obj.artName}
            />
          )}
          <div className="artName">
            <div
              // className="art"
              style={{
                marginBottom: "0rem",
              }}
            >
              Size:
            </div>
            <TextField
              sx={{
                marginBottom: "0rem",
              }}
              size="small"
              // label="Size"
              id="addSize"
              value={content.size}
              onChange={(e) =>
                setContent({ ...content, size: e.target.value })
              }
            />
          </div>
          <div className="artName">
            <div
              // className="art"
              style={{
                marginBottom: "0rem",
              }}
            >
              Year:
            </div>
            <TextField
              sx={{
                marginBottom: "0rem",
              }}
              size="small"
              // label="Year"
              id="addYear"
              value={content.year}
              onChange={(e) =>
                setContent({ ...content, year: e.target.value })
              }
            />
          </div>
          <div className="artName">
            <div
              // className="art"
              style={{
                marginBottom: "0rem",
              }}
            >
              Medium:
            </div>
            <TextField
              sx={{
                marginBottom: "0rem",
              }}
              size="small"
              // label="Medium"
              id="addMedium"
              value={content.medium}
              onChange={(e) =>
                setContent({ ...content, medium: e.target.value })
              }
            />
          </div>
          <div
            className="fieldSet"
            style={{
              marginBottom: "0rem",
            }}
          >
            <div >Status: </div>
            <div className="radiobuttonmain" style={{ display: "flex" }}>
              <label>Available</label>
              <input
                type="radio"
                value="Available"
                checked={content.statuss === "Available"}
                onChange={handleStatusChange}
              />
              <label>Sold</label>
              <input
                type="radio"
                value="Sold"
                checked={content.statuss === "Sold"}
                onChange={handleStatusChange}
              />
            </div>
          </div>

          <div className="fieldSet">
            <div >Feature: </div>
            <div className="radiobuttonmain" style={{ display: "flex" }}>
              <label>Yes </label>
              <input
                type="radio"
                value="Yes"
                checked={content.feature === "Yes"}
                onChange={handleFeatureChange}
              />
              <label>No</label>
              <input
                type="radio"
                value="No"
                checked={content.feature === "No"}
                onChange={handleFeatureChange}
              />
            </div>
          </div>
          <div
            className="uploadContainer"
            style={{
              display: "flex",
            }}
          >
            <button style={{ backgroundColor: '#505050' }}>
              {isEditMode ? "Update Art" : "Upload Art"}
            </button>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
              {showSuccess && (
                <div style={{ padding: '0.2rem' }}>
                  {isEditMode ? "Updated" : "Uploaded"} successfully!
                </div>
              )}
              {loading && (
                <div style={{ width: '100%', display: 'flex', outline: "none", fontFamily: 'lobster', fontSize: '1.2rem', justifyContent: 'center', alignItems: "center" }}>
                  <div className="dot-container">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div >
      </form >
    </div >
  );
}

export default AddArt;
