import React from 'react'
import { useMediaQuery, useTheme } from "@mui/material";
import about from "../../assets/arts/about.jpg"
import '../../global.css';

function About() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column', padding: '3rem', textAlign: 'justify' }}>
            {/* <div className='title' style={{ marginLeft: isMobile ? '45%' : '48%' }}>About</div> */}
            <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column', paddingInline: isMobile ? '' : '10rem' }}>
                <img width='100%' src={about} alt='img' />
                <div className='title2' style={{ textTransform: 'none', fontSize: isMobile ? '1.2rem' : '1.4rem' }}>
                    <strong>Dawani Art Gallery</strong>, founded by architect and passionate art collector Murlidhar Dawani, is an online platform dedicated to showcasing meticulously curated art, prints, and sculptures from leading South Asian and global artists.

                    The gallery features works by renowned artists such as Anwar Saeed, Jamil Naqsh, Tassaduq Sohail, Nagori, Colin David, and Bashir Mirza, offering a space where contemporary and traditional art converge in an inspiring collection.
                </div>
            </div>
        </div >
    )
}

export default About