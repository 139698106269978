import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { useState } from "react";
import "./addArtist.css";
import { useDispatch, useSelector } from "react-redux";
import FileBase from "react-file-base64";
import "./addArtist.css";
import { addArtistAsync, updateArtistAsync } from "../../redux/ArtistSlice";
import LoadingSpinner from "../circularProgress/LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";


function AddArtist() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { obj, type } = location.state || {};
  const isEditMode = type === 'artist' ? !!obj : false;

  const [content, setContent] = useState({
    artistName: type === 'artist' ? obj?.artistName || "" : "",
    lifeSpan: type === 'artist' ? obj?.lifeSpan || "" : "",
    artistInfo: type === 'artist' ? obj?.artistInfo || "" : "",
    selectedFile: type === 'artist' ? obj?.selectedFile || "" : "",
    feature: type === 'artist' ? obj?.feature || "No" : "No",
  });

  const [fileKey, setFileKey] = useState(Date.now());
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [uploadAttempted, setUploadAttempted] = useState(false);
  const [loading, setLoading] = useState(false)
  const status = useSelector((state) => state.artist.status);

  const clear = () => {
    setContent({
      artistName: "",
      lifeSpan: "",
      artistInfo: "",
      feature: "No",
      selectedFile: "",
    });
    setErrors({});
    setFileKey(Date.now()); // Reset the file input by changing the key
  };

  const validate = () => {
    const newErrors = {};
    if (!content.artistName) newErrors.artistName = "Art name is required";
    if (!content.artistInfo)
      newErrors.artistInfo = "Artist Information is required";
    if (!content.selectedFile) newErrors.selectedFile = "Image is required";
    return newErrors;
  };

  const updatedContent = {
    ...content,
    _id: obj?._id, // Assuming obj._id exists
  };

  const handleFeatureChange = (event) => {
    setContent({ ...content, feature: event.target.value });
  };

  const updatedArtist = useSelector((state) =>
    state.artist.artists.find((artist) => artist._id === obj?._id)
  );

  useEffect(() => {
    if (uploadAttempted && (!isEditMode || updatedArtist) && status === "succeeded") {
      setShowSuccess(true)
      setTimeout(() => setShowSuccess(false), 3000);
      if (isEditMode && updatedArtist) {
        navigate(`/artist/artistinfo/${updatedArtist._id}`, {
          state: { obj: updatedArtist, type: "artist" },
        });
      }
      setLoading(false)
    }
  }, [status, uploadAttempted, updatedArtist, navigate])

  const handleUpload = (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0 || content.selectedFile === "") {
      setErrors(validationErrors);
      return;
    }
    if (isEditMode) {
      dispatch(updateArtistAsync(updatedContent));
    } else {
      dispatch(addArtistAsync(content));
    }
    setLoading(true)
    setUploadAttempted(true);
    clear();
  };

  return (
    <>
      <div>
        <div className="mArt" style={{}}>
          <form onSubmit={handleUpload} style={{ width: '25rem' }}>
            <div className="container">
              <div className="artistName" >
                <div

                  style={{
                    marginBottom: "0rem",
                  }}
                >
                  Artist Name: {isEditMode ? obj.artistName : null}
                </div>
                {!isEditMode ? <TextField
                  sx={{ height: "1rem" }}
                  size="small"
                  // label="Artist Name"
                  id="addArtist"
                  value={content.artistName}
                  onChange={(e) =>
                    setContent({ ...content, artistName: e.target.value })
                  }
                  required
                  error={!!errors.artistName}
                  helperText={errors.artistName}
                /> : null}
              </div>
              <div className="artistName" >
                <div

                  style={{
                    marginBottom: "0rem",
                  }}
                >
                  Lifespan:
                </div>
                <TextField
                  sx={{ height: "1rem" }}
                  size="small"
                  // label="lifespan"
                  id="addLifeSpan"
                  value={content.lifeSpan}
                  onChange={(e) =>
                    setContent({ ...content, lifeSpan: e.target.value })
                  }
                />
              </div>
              <div className="artistInfo" style={{ marginBottom: "1rem" }}>
                <div  >Artist Information:</div>
                <TextField
                  multiline
                  rows={5}
                  sx={{ height: "7rem", width: "100%" }}
                  size="small"
                  // label="Artist Information"
                  id="addArtist"
                  value={content.artistInfo}
                  onChange={(e) =>
                    setContent({ ...content, artistInfo: e.target.value })
                  }
                  required
                  error={!!errors.artistInfo}
                  helperText={errors.artistInfo}
                />
              </div>
              <div className="imageSelection">
                <div >Choose Image: </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "100%",
                  }}
                >
                  <FileBase
                    key={fileKey}
                    type="file"
                    size="small"
                    multiple={false}
                    onDone={({ base64 }) =>
                      setContent({ ...content, selectedFile: base64 })
                    }
                    required
                  />
                </div>
                {errors.selectedFile && (
                  <div style={{ color: "red", fontSize: "1rem" }}>
                    {errors.selectedFile}
                  </div>
                )}
              </div>
              {isEditMode && <img
                style={{
                  width: "100%",
                  height: "20rem",
                  objectFit: 'contain',
                }}
                src={obj.selectedFile}
                alt={obj.artName}
              />}
              <div className="fieldSet">
                <div  >Feature: </div>
                <div className="radiobuttonmain" style={{ display: "flex" }}>
                  <label>Yes </label>
                  <input
                    type="radio"
                    value="Yes"
                    checked={content.feature === "Yes"}
                    onChange={handleFeatureChange}
                  />
                  <label>No</label>
                  <input
                    type="radio"
                    value="No"
                    checked={content.feature === "No"}
                    onChange={handleFeatureChange}
                  />
                </div>
              </div>
              <div
                className="uploadContainer"
                style={{
                  display: "flex",
                }}
              >
                <button style={{ backgroundColor: '#505050' }}>
                  {isEditMode ? "Update" : "Upload"} Artist
                </button>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                  {showSuccess && (
                    <div style={{ padding: '0.2rem' }}>
                      {isEditMode ? "Updated" : "Uploaded"} successfully!
                    </div>
                  )}
                  {loading && (
                    <div style={{ width: '100%', display: 'flex', outline: "none", fontFamily: 'lobster', fontSize: '1.2rem', justifyContent: 'center', alignItems: "center" }}>
                      <div className="dot-container">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddArtist;
