import React from "react";
import Auth from "../../components/auth/Auth";

function AddArtAdmin() {
  return (
    <>
      {/* <text>Add Art by admiin</text> */}
      <Auth />
    </>
  );
}

export default AddArtAdmin;
