import React from 'react'
import { useMediaQuery, useTheme } from "@mui/material";
import '../../global.css';

function Terms() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div style={{ padding: '1rem', paddingInline: isMobile ? '' : '10rem', textAlign: 'justify' }}>
            <div className='title' style={{ textAlign: 'center', marginBottom: '1rem' }}>Terms and Conditions</div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div className='textBody' style={{}}>
                    <div >Welcome to Dawani Art Gallery! These terms and conditions outline the rules and regulations for the use of our website, located at  <a href="https://www.dawaniartgallery.com" style={{ color: 'black', textDecoration: 'none' }} target="_blank">www.dawaniartgallery.com</a>. By accessing this website, we assume you accept these terms and conditions. Do not continue to use Dawani Art Gallery if you do not agree to all of the terms and conditions stated on this page.</div>
                    <div style={{ padding: '1rem' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>1. Introduction</div>
                            <div style={{ padding: '1rem' }}>
                                Dawani Art Gallery is an online platform showcasing and selling artworks. We do not have a physical gallery at this time. By accessing or using our website, you agree to comply with and be bound by these terms and conditions.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>2. Definitions</div>
                            <div style={{ padding: '1rem', direction: 'column' }}>
                                <div>"Website" refers to Dawani Art Gallery's website at  <a href="https://www.dawaniartgallery.com" style={{ color: 'black', textDecoration: 'none' }} target="_blank">www.dawaniartgallery.com</a> .</div>
                                <div>"User" refers to any individual who accesses or uses the website.</div>
                                <div>"Artwork" refers to any piece of art listed for sale on the website.</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>3. Use of the Website</div>
                            <div style={{ padding: '1rem' }}>
                                By using our website, you represent that you are at least the age of majority in your jurisdiction. You agree to use the website only for lawful purposes and in accordance with these terms.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>4. User Accounts</div>
                            <div style={{ padding: '1rem' }}>
                                To access certain features of our website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. Dawani Art Gallery reserves the right to suspend or terminate your account and access to the website if you violate these terms and conditions or engage in any prohibited activities as outlined in Section 9.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>5. Purchases and Payments</div>
                            <div style={{ padding: '1rem' }}>
                                All artworks displayed on the website are available for purchase. By placing an order, you agree to provide accurate and complete information and to pay the listed price for the artwork. The user is responsible for any taxes, duties, or customs charges that may apply to their purchase. All sales are final, and we do not accept returns.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>6. Shipping and Delivery</div>
                            <div style={{ padding: '1rem' }}>
                                We will arrange for the delivery of the purchased artwork to the address you provide. Shipping times and costs will vary based on your location and the size of the artwork. We are not responsible for any delays or damage during shipping.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>7. Returns and Refunds</div>
                            <div style={{ padding: '1rem' }}>
                                All sales are final. We do not accept returns or offer refunds unless the artwork received is significantly different from its description on the website (e.g., wrong artwork, size discrepancy, damage not described). In such cases, the user must contact Dawani Art Gallery within 14 days of receipt to initiate a review for possible refund or replacement.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>8. Intellectual Property</div>
                            <div style={{ padding: '1rem' }}>
                                All content on this website, including text, graphics, logos, images, and software, is the property of Dawani Art Gallery or its content suppliers and is protected by international copyright laws.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div> 9. User Conduct</div>
                            <div style={{ padding: '1rem' }}>
                                Users agree not to use the website in any way that may harm Dawani Art Gallery, its artists, or other users. Prohibited activities include, but are not limited to, harassment, defamation, or posting offensive content. Users may post reviews, comments, and other content; submit suggestions, ideas, comments, questions, or other information, as long as the content is not illegal, obscene, threatening, defamatory, or otherwise injurious to third parties. Dawani Art Gallery reserves the right (but not the obligation) to remove or edit such content.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div> 10. Privacy Policy</div>
                            <div style={{ padding: '1rem' }}>
                                Please refer to our [Privacy Policy] for information on how we collect, use, and protect your personal data.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>11. Disclaimers and Limitation of Liability</div>
                            <div style={{ padding: '1rem' }}>
                                Dawani Art Gallery provides the website and its services on an "as is" basis without any warranties of any kind. We are not liable for any damages arising from the use of the website or the purchase of any artworks.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>12. Indemnification</div>
                            <div style={{ padding: '1rem' }}>
                                You agree to indemnify, defend, and hold harmless Dawani Art Gallery, its officers, directors, employees, agents, and affiliates, from any claims, liabilities, damages, and expenses (including reasonable attorneys' fees) arising out of your use of the website, violation of these terms, or infringement of any third-party rights.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div> 13. Changes to Terms</div>
                            <div style={{ padding: '1rem' }}>
                                We reserve the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting to the website. Your continued use of the website constitutes acceptance of the modified terms.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>14. Governing Law and Jurisdiction</div>
                            <div style={{ padding: '1rem' }}>
                                These Terms and Conditions shall be governed by and construed in accordance with the laws of Ontario. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Ontario.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div> 15. Force Majeure</div>
                            <div style={{ padding: '1rem' }}>
                                Dawani Art Gallery shall not be liable for any failure to perform its obligations under these terms where such failure results from any cause beyond Dawani Art Gallery's reasonable control, including but not limited to acts of God, war, strikes, or other industrial disputes, breakdown of systems or network access, or flood, fire, explosion, or accident.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div>16. Severability</div>
                            <div style={{ padding: '1rem' }}>
                                If any provision of these terms and conditions is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity will not render these terms and conditions unenforceable or invalid as a whole, and such provisions will be deleted without affecting the remaining provisions herein.
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
                            <div> 17. Contact Information</div>
                            <div style={{ padding: '1rem' }}>
                                If you have any questions or concerns about these terms and conditions, please contact us at  <a href="mailto:support@dawaniartgallery.com" style={{ textDecoration: 'none', color: 'black' }}>support@dawaniartgallery.com</a>.
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms