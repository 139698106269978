import { CircularProgress, styled } from "@mui/material";

const CenteredCircularProgress = styled(CircularProgress)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

export default CenteredCircularProgress;
