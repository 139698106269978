import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import dotenv from "dotenv";
import axios from "axios";
// import { react } from "react";

// dotenv.config();
const PORT = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: PORT,
});

// Define an async thunk for adding art
export const addArtAsync = createAsyncThunk(
  "arts/addArtAsync",
  async (art, { rejectWithValue }) => {
    try {
      // ("AddArt attempted: ", art);
      const response = await api.post("/arts/addart", art);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Define an async thunk for updating an art
export const updateArtAsync = createAsyncThunk(
  "art/updateArt",
  async (art, { rejectWithValue }) => {
    try {
      const response = await api.put(`/arts/updateart/${art._id}`, art);
      // (response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Define an async thunk for deleting an art
export const deleteArtAsync = createAsyncThunk(
  "arts/deleteArtAsync",
  async (artId, { rejectWithValue }) => {
    // ("async thuck: ", artId);
    try {
      const response = await api.delete(`/arts/deleteart/${artId}`);
      // (
      //   "return Art ID from backend art for update state of deleted art: ",
      //   artId
      // );
      return artId; // Return the artId to remove it from the state
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Define an async thunk for sendinfg inquiry message for an art 06/07
export const sendArtInquiryAsync = createAsyncThunk(
  "arts/sendArtInquiryAsync",
  async (art, { rejectWithValue }) => {
    try {
      const response = await api.post("/arts/artinfo/inquiry", art);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchArtInfo = createAsyncThunk(
  'arts/fetchArtInfo',
  async (artId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/arts/fetchartinfo/${artId}`);
      return response.data;
    } catch (error) {
      // Ensure that error.response.data exists
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: 'Unexpected error occurred.' });
      }
    }
  }
);

// Fetch Feature arts for the home page banner
export const fetchFeatureArtsAsync = createAsyncThunk(
  "arts/fetchFeatureArtsAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/arts/featurearts");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch NON Feature arts for the home page banner
export const fetchNonFeatureArtsAsync = createAsyncThunk(
  "arts/fetchNonFeatureArtsAsync",
  async (page, { rejectWithValue }) => {
    try {
      const response = await api.get(`/arts/all?page=${page}`);
      return response.data;
    } catch (error) {
      // ("TOP THUNCK DATA: ERROR", error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Define an async thunk for getting artist's arts
export const fetchArtistArtsAsync = createAsyncThunk(
  "arts/fetchArtistArtsAsync",
  async (artistId, { rejectWithValue }) => {
    try {
      // ("Artist arts fetching started 11111");
      const response = await api.get(`/artists/artistinfo/${artistId}`);
      // ("Artist arts fetched successfully", response);
      return response.data;
    } catch (error) {
      // ("Fetching artist arts failed", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const artsSlice = createSlice({
  name: "arts",
  initialState: {
    featuredArts: [],
    nonFeaturedArts: [],
    arts: [],
    artInfo: {},
    artistArts: [],
    status: "idle",
    statusArtInfo: "idle",
    statusArtistArt: "idle",
    error: null,
    allArts: false,
    hasMore: true,
    page: 1,
  },
  reducers: {
    clearArtistArts(state) {
      state.artistArts = [];
    },
    setAllArts(state) {
      state.allArts = !state.allArts;
    },
    setPage(state) {
      state.page += 1;
    },
    updateArt(state, action) {
      if (state.artInfo._id === action.payload._id) {
        state.artInfo.likeCount = action.payload.likeCount
      }
      if (action.payload.feature === 'Yes') {
        let index = state.featuredArts.findIndex(
          (art) => art._id === action.payload._id
        );
        state.featuredArts[index] = action.payload;
      } else {
        let index = state.nonFeaturedArts.findIndex(
          (art) => art._id === action.payload._id
        );
        state.nonFeaturedArts[index] = action.payload;
      }
      let index = state.arts.findIndex(
        (art) => art._id === action.payload._id
      );
      state.arts[index] = action.payload;
      index = state.artistArts.findIndex(
        (art) => art._id === action.payload._id
      );
      if (index !== -1) {
        state.artistArts[index] = action.payload;
      }

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addArtAsync.pending, (state) => {
        state.status = "loading";
        // ("Status loading");
      })
      .addCase(addArtAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        // ("Status Successful");
        state.arts.push(action.payload);
        if (action.payload.feature === "Yes") {
          state.featuredArts.push(action.payload);
        } else {
          state.nonFeaturedArts.push(action.payload);
        }
      })
      .addCase(addArtAsync.rejected, (state, action) => {
        state.status = "failed";
        // ("Status FAILED");
        state.error = action.payload;
      })

      .addCase(updateArtAsync.pending, (state) => {
        state.status = "loading";
        // ("Status loading");
      })
      .addCase(updateArtAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.feature === 'Yes') {
          let index = state.featuredArts.findIndex(
            (art) => art._id === action.payload._id
          );
          if (index !== -1) {
            state.featuredArts[index] = action.payload;
          } else {
            state.featuredArts.push(action.payload);
          }
          index = state.nonFeaturedArts.findIndex(
            (art) => art._id === action.payload._id
          );
          if (index !== -1) {
            state.nonFeaturedArts.splice(index, 1)
          }
        } else {
          let index = state.nonFeaturedArts.findIndex(
            (art) => art._id === action.payload._id
          );
          if (index !== -1) {
            state.nonFeaturedArts[index] = action.payload;
          } else {
            state.nonFeaturedArts.push(action.payload);
          }
          index = state.featuredArts.findIndex(
            (art) => art._id === action.payload._id
          );
          if (index !== -1) {
            state.featuredArts.splice(index, 1)
          }
        }
        let index = state.arts.findIndex(
          (art) => art._id === action.payload._id
        );
        if (index !== -1) {
          state.arts[index] = action.payload;
        } else {
          state.arts.push(action.payload);
        }
      })
      .addCase(updateArtAsync.rejected, (state, action) => {
        state.status = "failed";
        // ("Status FAILED");
        state.error = action.payload;
      })
      // .addCase(fetchArtsAsync.pending, (state) => {
      //   state.status = "loading";
      //   // ("Fetchartsasync data resonse LOADING: ");
      // })
      // .addCase(fetchArtsAsync.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   state.arts = action.payload; // Update arts array with fetched data
      //   // ("Fetchartsasync data resonse FULFILLED: ", state.arts);
      // })
      // .addCase(fetchArtsAsync.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.payload;
      //   // ("Fetchartsasync data resonse FAILED: ");
      // })

      .addCase(fetchFeatureArtsAsync.pending, (state) => {
        state.status = "loading";
        // ("fetchFeatureArtsAsync data resonse LOADING: ");
      })
      .addCase(fetchFeatureArtsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.featuredArts = action.payload; // Update arts array with fetched data
        // state.arts.push(...action.payload);
        // ("fetchFeatureArtsAsync data resonse FULFILLED: ", state.arts);
      })
      .addCase(fetchFeatureArtsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        // ("fetchFeatureArtsAsync data resonse FAILED: ");
      })

      .addCase(fetchNonFeatureArtsAsync.pending, (state) => {
        state.status = "loading";
        // state.arts.push(...action.payload);
        // ("fetchNonFeatureArtsAsync data resonse LOADING: ");
      })
      .addCase(fetchNonFeatureArtsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.length < 10) {
          state.hasMore = false;
        }
        const existingIds = new Set(state.arts.map(art => art._id)); // Assuming each art has a unique 'id'
        const filteredArts = action.payload.filter(art => !existingIds.has(art._id));
        state.arts.push(...filteredArts);
        // state.arts.push(...action.payload);
        // state.nonFeaturedArts = action.payload; // Update arts array with fetched data
        // state.arts = action.payload;
        // ("fetchNonFeatureArtsAsync data resonse FULFILLED: ", state.arts);
      })
      .addCase(fetchNonFeatureArtsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        // ("fetchNonFeatureArtsAsync data resonse FAILED: ");
      })
      .addCase(fetchArtistArtsAsync.pending, (state) => {
        state.statusArtistArt = "loading";
        // ("Fetching artist arts: LOADING");
      })
      .addCase(fetchArtistArtsAsync.fulfilled, (state, action) => {
        state.statusArtistArt = "succeeded";
        state.artistArts = action.payload;
      })
      .addCase(fetchArtistArtsAsync.rejected, (state, action) => {
        state.statusArtistArt = "failed";
        state.error = action.payload;
        // ("Fetching artist arts: FAILED");
      })
      .addCase(sendArtInquiryAsync.pending, (state) => {
        state.status = "loading";
        // ("Status loading");
      })
      .addCase(sendArtInquiryAsync.fulfilled, (state, action) => {
        // ("sendArtInquiryAsync action: ", action.payload);
        state.status = "succeeded";
        // ("Status Successful");
        state.arts.push(action.payload);
      })
      .addCase(sendArtInquiryAsync.rejected, (state, action) => {
        state.status = "failed";
        // ("Status FAILED");
        state.error = action.payload;
      })
      .addCase(deleteArtAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteArtAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.arts = state.arts.filter((art) => art._id !== action.payload);
        state.featuredArts = state.featuredArts.filter(
          (art) => art._id !== action.payload
        );
        state.nonFeaturedArts = state.nonFeaturedArts.filter(
          (art) => art._id !== action.payload
        );
      })
      .addCase(deleteArtAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchArtInfo.pending, (state) => {
        state.statusArtInfo = "loading";
      })
      .addCase(fetchArtInfo.fulfilled, (state, action) => {
        state.statusArtInfo = "succeeded";
        state.artInfo = action.payload.artInfo
      })
      .addCase(fetchArtInfo.rejected, (state) => {
        state.statusArtInfo = "failed";
        state.message = "Art not found.";
      });
  },
});

export const { removeArt, updateArt, clearArtistArts, setAllArts, updateLikeCount, setPage } = artsSlice.actions;
export default artsSlice.reducer;
