// ArtistDropdown.js with scrolling

import React, { useState } from "react";
import { TextField, MenuItem } from "@mui/material";

const ArtistDropdown = ({ artists, onSelect, isEditMode }) => {
  const [selectedArtist, setSelectedArtist] = useState("");

  const handleSelect = (event) => {
    const artist = event.target.value;
    setSelectedArtist(artist);
    onSelect(artist);
  };

  return (
    <TextField
      select
      label="Artist Name"
      value={selectedArtist}
      onChange={handleSelect}
      size="small"
      fullWidth
      required={!isEditMode}
      variant="outlined"
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        },
      }}
    >
      <MenuItem value="">
        <em>Select an artist</em>
      </MenuItem>
      {artists.map((artist) => (
        <MenuItem key={artist._id} value={artist}>
          {/* <MenuItem key={artist._id} value={artist.artistName}> */}
          {artist.artistName}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ArtistDropdown;
