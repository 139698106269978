import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import '../../global.css';
import { useMediaQuery, useTheme, IconButton } from "@mui/material";

import { Visibility, VisibilityOff } from '@mui/icons-material';

import "./styles.css";

const Auth = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickArtPage = (e) => {
    e.preventDefault();

    const secretKey = process.env.REACT_APP_VALID_PASSWORD;

    if (password === process.env.REACT_APP_MYNAME) {
      const encryptedPassword = CryptoJS.AES.encrypt(
        password,
        secretKey
      ).toString();
      localStorage.setItem("encryptedPassword", encryptedPassword);
      navigate("/addartvalidation");
    } else {
      setMessage("Incorrect Password.");
      setTimeout(() => setMessage(""), 3000);
    }
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevPasswordVisible) => !prevPasswordVisible);
  };

  return (
    <div
      // className="outerbody"
      style={{
        display: "flex", flexDirection: 'column', padding: '1rem', width: '100%',
      }}
    >
      <div className="title" style={{ marginLeft: isMobile ? '40%' : '45%', marginBottom: '1rem' }}>
        Admin Access
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }} >
        <form onSubmit={handleClickArtPage}>

          <div className="form-group">
            <div className="password-container">
              <div sx={{ fontSize: '1rem' }}>
                Password:
              </div>
              {/* <label htmlFor="password">Password:</label> */}
              <div
                className="textBody"
                style={{
                  position: 'relative', width: '100%',
                  width: "100%",
                  marginTop: "0.5rem",
                }}
              >
                <style>
                  {`
                      input::-ms-reveal {
                        display: none;
                      }
                    `}
                </style>

                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  className="password-input"
                  placeholder="Enter password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <IconButton
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    padding: 0,
                  }}
                >
                  {passwordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>
              {/* <div
                className="inputcont"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "0.5rem",
                  fontFamily: 'lobster', fontSize: '1rem'
                }}
              >
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  // className="password-input"
                  placeholder="Enter your password"
                  name="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{ width: "90%", fontSize: '1rem' }}
                />
                <button
                  type="button"
                  id="togglePassword"
                  className="inputcont"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    backgroundColor: "white",
                    border: "none",
                    width: "25%",
                    fontSize: '1rem'
                  }}
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? "Hide" : "Show"}
                </button>
              </div> */}
            </div>
          </div>
          <div className="uploadContainer" style={{ display: "flex", width: '100%', }}>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
              {message !== '' &&
                <div style={{ padding: '0.2rem' }}>
                  {message}
                </div>}
            </div>
            <button style={{ width: '7rem', backgroundColor: '#505050' }} type="submit" onClick={handleClickArtPage}>
              Submit
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default Auth;
