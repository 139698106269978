import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const PORT = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
    baseURL: PORT,
});

export const searchWord = createAsyncThunk(
    "search/searchWord",
    async (data, { rejectWithValue }) => {
        try {
            const response = await api.post("/search/searchWord", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const searchSlice = createSlice({
    name: "search",
    initialState: {
        status: 'idle',
        message: '',
        searchedWord: '',
        searchResult: [],
    },
    reducers: {
        setSearchWord(state, action) {
            state.searchedWord = action.payload
        },
        clearSearch(state) {
            state.searchedWord = '';
            state.searchResult = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchWord.pending, (state) => {
                state.status = "loading";
            })
            .addCase(searchWord.fulfilled, (state, action) => {
                state.status = "succeeded";
                if (action.payload.length > 0) {
                    state.searchResult = action.payload
                    state.message = 'Got it'
                } else {
                    state.searchResult = action.payload
                    state.message = `We couldn't find any art/artist matching your search (${state.searchedWord}). Try using different keywords.`
                }
            })
            .addCase(searchWord.rejected, (state, action) => {
                state.status = "failed";
                state.message = "Please try again."
            });
    }
})


export const { setSearchWord, clearSearch } = searchSlice.actions;
export default searchSlice.reducer;