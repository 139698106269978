import { configureStore, combineReducers } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import artsSlice from "./ArtSlice";
import ArtistSlice from "./ArtistSlice";
import userSlice from "./userSlice";
import SearchSlice from "./SearchSlice";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["art", "artist", "user", "search"],
};

const rootReducer = combineReducers({
  art: artsSlice,
  artist: ArtistSlice,
  user: userSlice,
  search: SearchSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
