import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ArtsCopy from "../../components/arts/ArtsCopy";
import { setInquiriedArts, logout, movetoSeen } from "../../redux/userSlice";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';

function Profile() {

    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

    const user = useSelector((state) => state.user.user)
    const followingArtist = useSelector((state) => state.user.followingArtist)
    const savedArts = useSelector((state) => state.user.savedArts)

    const [button, setButton] = useState('arts')
    const [content, setContent] = useState('')

    const inquiriedArts = useSelector((state) => state.user.inquiriedArts);
    const inquiriedArtsInfo = useSelector((state) => state.user.inquiriedArtsInfo)

    function timeAgo(dateString) {
        const now = new Date();
        const pastDate = new Date(dateString);

        const diffInMs = now - pastDate;
        const diffInSeconds = Math.floor(diffInMs / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);

        if (diffInSeconds < 60) {
            return `${diffInSeconds} seconds ago`;
        } else if (diffInMinutes < 60) {
            return `${diffInMinutes} minutes ago`;
        } else if (diffInHours < 24) {
            return `${diffInHours} hours ago`;
        } else {
            return `${diffInDays} days ago`;
        }
    }

    const handleFilterToggle = (value) => {
        dispatch(setInquiriedArts(value));

    };

    const navigate = useNavigate();
    const handleClickUser = () => {
        dispatch(logout())
        localStorage.removeItem("encryptedPassword");
        navigate("/");
    }

    const [showAdminMenu, setShowAdminMenu] = useState(false)

    useEffect(() => {
        if (user && user.email === 'admin@dawaniartgallery.com') {
            setShowAdminMenu(true)
        }
    }, [user])

    useEffect(() => {
        if (button === 'arts') {
            setContent(<ArtsCopy name={inquiriedArts === 'Enquired' ? 'ENQUIRED' : 'SAVED'} data={inquiriedArts === 'Enquired' ? inquiriedArtsInfo : savedArts} type='profileArts'
                onArtFilterToggle={handleFilterToggle}
                showAllArts={inquiriedArts}
            />)
        } else if (button === 'following') {
            setContent(<ArtsCopy name='' data={followingArtist} type='followingArtist'
            // onArtFilterToggle={''}
            // showAllArts={''} 
            />)
        } else if (button === 'notification') {
            if (user && user.newArtNotification.length > 0) {
                dispatch(movetoSeen({ artData: user.newArtNotification, email: user.email.toLowerCase() }))
            }
            setContent(
                <div style={{
                    display: 'flex', padding: '1rem', width: '100%',
                    flexDirection: 'column', alignItems: 'center'
                }}>
                    {user.newArtNotification.length > 0 || user.seenArtNotification.length > 0 ? <> <div className='title' style={{ marginBottom: '1rem' }}>Notification</div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {user?.newArtNotification.map((item, index) => (
                                <div key={index} style={{
                                    padding: '0.5rem',
                                    backgroundColor: '#f9f9f9',

                                    color: '#333',
                                    lineHeight: '1.5',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                }}
                                    onClick={() => { navigate(`/art/artinfo/${item.artId}`, { state: { item, type: 'art' } }); }}
                                >
                                    <strong style={{ color: '#d9534f' }}>*New*</strong> Art by {item.artistName} has been added to the gallery! <div style={{ textDecoration: 'underline' }} >Check it out now!</div>
                                    <div style={{ marginTop: '5px', color: '#888' }}>{timeAgo(item.time)}</div>
                                </div>
                            ))}
                            {user?.seenArtNotification.map((item, index) => (
                                <div key={index} style={{
                                    padding: '0.5rem',
                                    backgroundColor: '#f9f9f9',

                                    color: '#333',
                                    lineHeight: '1.5',
                                }}
                                    onClick={() => { navigate(`/art/artinfo/${item.artId}`, { state: { item, type: 'art' } }); }}
                                >
                                    Art by {item.artistName} has been added to the gallery!<div style={{ textDecoration: 'underline' }}>Check it out now!</div>
                                    <div style={{ marginTop: '5px', color: '#888' }}>{timeAgo(item.time)}</div>
                                </div>
                            ))}
                        </div></> :
                        <div>You have no new notifications at the moment.</div>
                    }
                </div >
            )
        }

    }, [button, followingArtist, inquiriedArtsInfo, inquiriedArts])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center', padding: '1rem'
            }}>
                <div style={{ flex: 1, }}></div>
                <div className='title' style={{ flex: 'none', }}>
                    Welcome {user?.name}
                </div>

                <div style={{ display: 'flex', flex: 1, justifyContent: 'end', outline: "none", alignContent: 'center', textDecoration: 'underline', }} >
                    {showAdminMenu && <div onClick={() => { navigate("/auth") }} style={{ marginRight: isMobile ? '0.5rem' : '1rem', outline: "none", cursor: 'pointer', }}><AddToQueueIcon sx={{ fontSize: isMobile ? '1.2rem' : '1.4rem' }} /></div>}
                    <div onClick={() => {
                        setButton('notification');
                    }} style={{ marginRight: isMobile ? '0.5rem' : '1rem', outline: "none", cursor: 'pointer', position: 'relative', display: 'inline-block' }}><NotificationsNoneIcon sx={{ fontSize: isMobile ? '1.2rem' : '1.4rem' }} />
                        {user?.newArtNotification.length > 0 && (
                            <span style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                width: '8px',
                                height: '8px',
                                backgroundColor: 'red',
                                borderRadius: '50%',
                                border: '1px solid white',
                            }}></span>
                        )}
                    </div>
                    <div onClick={handleClickUser} style={{ outline: "none", cursor: 'pointer', }}> <LogoutIcon sx={{ fontSize: isMobile ? '1.2rem' : '1.4rem' }} /></div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', }}>
                <div
                    style={{
                        width: isMobile ? '10rem' : '12rem', cursor: 'pointer', textAlign: 'center', backgroundColor: button === 'arts' ? '#505050' : '#DCDCDC',
                        color: button === 'arts' ? 'white' : '', padding: '0.5rem', borderRadius: '0.2rem'
                    }}
                    onClick={() => {
                        setButton('arts');
                    }}
                    className='title'
                >
                    Art
                </div>
                <div
                    style={{
                        width: isMobile ? '10rem' : '12rem', cursor: 'pointer', textAlign: 'center', backgroundColor: button === 'following' ? '#505050' : '#DCDCDC',
                        color: button === 'following' ? 'white' : '', padding: '0.5rem', borderRadius: '0.2rem'
                    }}
                    onClick={() => {
                        setButton('following');
                    }}
                    className='title'
                >
                    Following ({followingArtist.length})
                </div>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>{content}</div>
        </div >
    )
}

export default Profile