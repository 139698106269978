// Redux slice
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateArt } from './ArtSlice';

const PORT = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
    baseURL: PORT,
});

export const sentOtp = createAsyncThunk(
    'user/sentOtp',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/sentOtp', formData);
            // localStorage.setItem('token', response.data.token);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const verifyOtp = createAsyncThunk(
    'user/verifyOtp',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/verifyOtp', formData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const movetoSeen = createAsyncThunk(
    'user/movetoSeen',
    async (artData, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/movetoSeen', artData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const setNewPassword = createAsyncThunk(
    'user/setNewPassword',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/setNewPassword', formData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const followArtist = createAsyncThunk(
    'user/followArtist',
    async (data, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/followArtist', data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const saveArt = createAsyncThunk(
    'user/saveArt',
    async (data, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/saveArt', data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const saveInquiryArt = createAsyncThunk(
    'user/saveInquiryArt',
    async (data, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/saveInquiryArt', data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const likeArt = createAsyncThunk(
    'user/likeArt',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const response = await api.post('/auth/likeArt', data);
            dispatch(updateArt(response.data.art));
            dispatch(updateSaveArt(response.data.art))
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const signup = createAsyncThunk(
    'user/signup',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/signup', formData);
            localStorage.setItem('token', response.data.token);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const signin = createAsyncThunk(
    'user/signin',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/signin', formData);
            localStorage.setItem('token', response.data.token);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchUserData = createAsyncThunk(
    'user/fetchUserData',
    async (token, { rejectWithValue }) => {
        try {
            const response = await api.get('/auth/fetchUserData', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // if (response.data.newArtNotification.length > 0) {
            //     // dispatch(fetchArtInfo')
            // }
            // if (response.data.seenArtNotification.length > 0) {

            // }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    });



const initialState = {
    user: null,
    jwt: localStorage.getItem('token') || null,
    followingArtist: [],
    savedArts: [],
    inquiriedArtsInfo: [],
    status: 'idle',
    inquiriedArts: 'SAVED',
    message: '',
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearStatus: (state) => {
            state.status = "idle";
            state.message = ""
        },
        setInquiriedArts: (state, action) => {
            state.inquiriedArts = action.payload
        },
        logout: (state) => {
            state.user = null;
            state.jwt = null;
            state.status = 'idle';
            state.status = 'idle';
            state.error = null;
            localStorage.removeItem('token');
        },
        updateSaveArt(state, action) {
            let index = state.savedArts.findIndex(
                (art) => art._id === action.payload._id
            );
            if (index !== -1) {
                state.savedArts[index].likeCount = action.payload.likeCount;
            }

            index = state.inquiriedArtsInfo.findIndex(
                (art) => art._id === action.payload._id
            );
            if (index !== -1) {
                state.inquiriedArtsInfo[index].likeCount = action.payload.likeCount;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sentOtp.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(sentOtp.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload.message
            })
            .addCase(sentOtp.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload.message
            })
            .addCase(verifyOtp.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload.message
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload.message
            })
            .addCase(movetoSeen.fulfilled, (state, action) => {
                state.user = action.payload.user
            })
            .addCase(setNewPassword.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(setNewPassword.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.jwt = action.payload.token;
                state.message = 'Password Updated!';
            })
            .addCase(setNewPassword.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload.message
            })
            .addCase(signup.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(signup.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.jwt = action.payload.token;
                state.followingArtist = action.payload.followingArtists;
                state.savedArts = action.payload.saveArts
                state.inquiriedArtsInfo = action.payload.inquiriedArts
                state.message = 'Signup successful!';
            })
            .addCase(signup.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload.message
            })
            .addCase(signin.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(signin.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.jwt = action.payload.token;
                state.followingArtist = action.payload.followingArtists;
                state.inquiriedArtsInfo = action.payload.inquiriedArts
                state.savedArts = action.payload.saveArts
                state.message = 'Signin successful!';
            })
            .addCase(signin.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload.message
            })
            .addCase(fetchUserData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.followingArtist = action.payload.followingArtists;
                state.savedArts = action.payload.saveArts
                state.inquiriedArtsInfo = action.payload.inquiriedArts
                state.status = 'succeeded';
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload;
            })
            .addCase(followArtist.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(followArtist.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.followingArtist = action.payload.followingArtists;
                state.status = 'succeeded';
            })
            .addCase(followArtist.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload;
            })
            .addCase(saveArt.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveArt.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.savedArts = action.payload.saveArts;
                state.status = 'succeeded';
            })
            .addCase(saveArt.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload;
            })
            .addCase(saveInquiryArt.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveInquiryArt.fulfilled, (state, action) => {
                state.inquiriedArtsInfo = action.payload.inquiriedArts;
                state.status = 'succeeded';
            })
            .addCase(saveInquiryArt.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload;
            })
            .addCase(likeArt.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(likeArt.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.status = 'succeeded';
            })
            .addCase(likeArt.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload;
            })
    },
});

export const { logout, clearStatus, updateSaveArt, setInquiriedArts } = userSlice.actions;
export default userSlice.reducer;
