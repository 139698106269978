import React, { useRef, useState, useEffect } from "react";
import Banner from "../../components/banner/Banner";
import Artist from "../../components/artist/Artistbar";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "./CustomArrows";
import "./Carousel.css";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/circularProgress/LoadingSpinner";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function Home() {

  // const sliderRef = useRef(null);
  const navigate = useNavigate();

  const featuredArts = useSelector((state) => state.art.featuredArts);

  const [currentIndex, setCurrentIndex] = useState(0);
  const numSlides = featuredArts.length;

  useEffect(() => {
    if (numSlides > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % numSlides);
      }, 4000); // Change slide every 3 seconds

      return () => clearInterval(interval);
    }
  }, [numSlides]);

  if (numSlides === 0) return <div style={{ height: '80vh', width: '100%', display: 'flex', outline: "none", fontFamily: 'lobster', fontSize: '1.2rem', justifyContent: 'center', alignItems: "center" }}>
    <div className="dot-container">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  </div>;

  return (
    <div style={{
      width: '100%'
    }}>
      <div className="slideshow-container" onClick={(e) => { navigate("/art") }}>
        <TransitionGroup>
          <CSSTransition
            key={currentIndex}
            timeout={2000} // Duration of the transition
            classNames="fade"
          >
            <img
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
              src={featuredArts[currentIndex]?.selectedFile}
              alt={`Slide ${currentIndex}`}
              className="slideshow-image"
            />
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div >
  );
}

export default Home;
