import React, { useState, useEffect } from "react";
// import Arts from "../../components/arts/Arts";
import ArtsCopy from "../../components/arts/ArtsCopy";
import { useSelector, useDispatch } from "react-redux";
import { setAllArtists } from '../../redux/ArtistSlice';

function ArtistPage() {

  const dispatch = useDispatch();
  const artists = useSelector((state) => state.artist.artists);
  const featureArtist = useSelector((state) => state.artist.featureArtist)
  const allArtist = useSelector((state) => state.artist.allArtist);

  // const handleArtistFilterToggle = () => {
  //   dispatch(setAllArtists());
  // };

  const [filteredArtists, setFilteredArts] = useState(artists);

  const [choice, setChoice] = useState('')

  const handleArtistFilterToggle = (setOption) => {
    let updatedArtists;

    if (setOption === 'Ascending') {
      setChoice('Ascending')
      updatedArtists = [...artists].sort((a, b) => a.artistName.localeCompare(b.artistName));
    } else if (setOption === 'Descending') {
      setChoice('Descending')
      updatedArtists = [...artists].sort((a, b) => b.artistName.localeCompare(a.artistName));
    }

    setFilteredArts(updatedArtists);
  };

  useEffect(() => {
    // Set the default state when arts change
    setFilteredArts(artists);
  }, [artists]);


  return <ArtsCopy name={choice} data={filteredArtists} type="artist"
    onArtFilterToggle={handleArtistFilterToggle}
    showAllArts={allArtist} />;
}

export default ArtistPage;
