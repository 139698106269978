import React from "react";
import {
  IconButton,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useLocation, useNavigate } from "react-router-dom";
import '../../global.css';

function Footer() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  return (
    <div
      style={{
        // height: "100%",
        // minHeight: "10vh",
        //Adjust maxheight after designing the footer
        // maxHeight: "20rem",
        padding: isMobile ? '0.8rem' : '1rem',
        // boxShadow: ' 5px rgba(0, 0, 0, 0.4)',
        display: 'flex',
        // boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.3)',
        flexDirection: 'row',
        borderTop: '.5px solid grey',
        paddingTop: '2rem',

        paddingInline: isMobile ? '' : '3rem',
        justifyContent: isMobile ? 'space-between' : 'space-between'
      }}
    >
      {/* <div style={{n}}> */}
      <div style={{ fontFamily: 'helvetica', fontSize: isMobile ? '1rem' : '1.1rem' }}>
        <div style={{ marginBottom: '.5rem', cursor: 'pointer', marginBottom: '1rem', fontWeight: 'bold', fontSize: isMobile ? '1rem' : '1.4rem', }} className="title" >DAWANI ART GALLERY</div>
        <div style={{ marginBottom: '.2rem', cursor: 'pointer', fontSize: isMobile ? '0.7rem' : '0.9rem', textTransform: 'uppercase' }} className="bodyText" onClick={() => {
          navigate("/about");
        }}>About</div>
        {/* <div>Contact</div> */}
        <div className="bodyText" style={{ marginBottom: '.5rem', cursor: 'pointer', marginBottom: '1rem', fontSize: isMobile ? '0.7rem' : '0.9rem', textTransform: 'uppercase' }}>Email: <a href="mailto:info@dawaniartgallery.com" style={{ textDecoration: 'none', color: 'black' }}>info@dawaniartgallery.com</a></div>
        {/* {/* <div style={{ marginBottom: '.5rem' }}>Inquiry: info@dawaniartgallery.com</div> */}
        <div style={{ marginBottom: '.5rem', cursor: 'pointer', display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginRight: '0.5rem' }} onClick={() => {
            navigate("/privacy");
          }} className="title4">Privacy and legal</div>
          <div onClick={() => {
            navigate("/terms");
          }} className="title4">Term and conditon</div>
        </div>
      </div>
      <div>
        {/* <div style={{ marginBottom: '.2rem', fontSize: isMobile ? '1rem' : '1.1rem', cursor: '-moz-grab' }}>Follow us</div> */}
        {/* <div > */}
        <InstagramIcon
          style={{ marginRight: '.2rem', cursor: 'pointer' }}
          onClick={() => {
            const instagramAppUrl = 'instagram://user?username=dawaniartgallery';
            const instagramWebUrl = 'https://www.instagram.com/dawaniartgallery?igsh=YjV5ZnU2ZmFncGps';

            // Try to open the Instagram app, but if it fails, open the web link
            window.location.href = instagramAppUrl;

            // After a short delay, open the web fallback in case the app doesn't open
            setTimeout(() => {
              window.open(instagramWebUrl, '_blank');
            }, 500); // Adjust timeout if necessary
          }}
        />
        <FacebookIcon
          style={{ marginRight: '.2rem', cursor: 'pointer' }}
          onClick={() => {
            const facebookAppUrl = 'fb://profile/61562153036462'; // Deep link to Facebook app profile
            const facebookWebUrl = 'https://www.facebook.com/profile.php?id=61562153036462&mibextid=ZbWKwL'; // Web fallback

            // Try to open the Facebook app, but if it fails, open the web link
            window.location.href = facebookAppUrl;

            // Fallback to the web link after a short delay
            setTimeout(() => {
              window.open(facebookWebUrl, '_blank');
            }, 500);
          }}
        />
        {/* </div> */}
      </div>
      {/* </div> */}
    </div>
  );
}

export default Footer;
