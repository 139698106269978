import { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NavBar from "./components/bar/NavBar";
import SideBar from "./components/bar/SideBar";
import Footer from "./components/bar/Footer";
import Home from "./pages/home/Home";
import "./App.css";
import AddArtAdmin from "./pages/addArtAdmin/AddArtAdmin";
// import Auth from "./components/auth/Auth";
import AddArtValidation from "./components/addArt/AddArtValidation";

import { useDispatch, useSelector } from "react-redux";
import { fetchNonFeatureArtsAsync, fetchFeatureArtsAsync } from "./redux/ArtSlice";
import { fetchUserData } from "./redux/userSlice";
import ArtsPage from "./pages/arts/ArtsPage";
import ArtistsPage from "./pages/artist/ArtistsPage";
import { fetchArtistsAsync } from "./redux/ArtistSlice";
import ArtistInfo from "./components/artist/ArtistInfo";
import ArtInfo from "./components/arts/artInfo/Artinfo";
import Inquiry from "./components/inquiry/Inquiry";
import AuthForm from "./pages/auth/AuthForm";
import Profile from "./pages/user/Profile";
import Search from "./pages/search/Search";
import About from "./pages/about/About";
import Privacy from "./pages/about/Privacy";
import Terms from "./pages/about/Terms";

function App() {
  const dispatch = useDispatch();

  const [showSideBar, setShowSideBar] = useState(false);
  const sidebarRef = useRef(null);
  const token = useSelector((state) => state.user.jwt);


  const featuredArts = useSelector((state) => state.art.featuredArts);
  // const arts = useSelector((state) => state.art.nonFeaturedArts);
  const featureArtist = useSelector((state) => state.artist.featureArtist);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setShowSideBar(false);
      }
    };

    if (showSideBar) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSideBar]);

  useEffect(() => {
    if (featureArtist.length === 0) {
      dispatch(fetchArtistsAsync());
    }
  }, [dispatch, featureArtist]);

  useEffect(() => {
    if (featuredArts.length === 0) {
      dispatch(fetchFeatureArtsAsync());
    }
  }, [dispatch, featuredArts]);

  // useEffect(() => {
  //   if (nonFeaturedArts.length === 0) {
  //     dispatch(fetchNonFeatureArtsAsync(1));
  //   }
  // }, [dispatch, nonFeaturedArts]);

  useEffect(() => {
    if (token) {
      dispatch(fetchUserData(token));
    }
  }, [dispatch, token]);

  return (
    <Router>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100vh",
        }}
      >
        <NavBar setShowSideBar={setShowSideBar} />
        <div className={`sidebar ${showSideBar ? "open" : ""}`}
          ref={sidebarRef}>
          <SideBar setShowSideBar={setShowSideBar} />
        </div>
        <main style={{ display: 'flex', flex: 1 }}>
          <Routes >
            <Route path="/" exact="true" element={<Home />} />
            <Route path="/auth" exact="true" element={<AddArtAdmin />} />
            <Route path="/art" exact="true" element={<ArtsPage />} />
            <Route path="/artist" exact="true" element={<ArtistsPage />} />
            <Route
              path="/artist/artistinfo/:id"
              exact="true"
              element={<ArtistInfo />}
            />
            <Route
              path="/art/artinfo/:id"
              exact="true"
              element={<ArtInfo />}
            />
            <Route
              path="/art/artinfo/:id/inquiry"
              exact="true"
              element={<Inquiry />}
            />
            <Route
              path="/addartvalidation"
              exact="true"
              element={<AddArtValidation />}
            />
            <Route
              path="/user/auth/:type"
              exact
              element={<AuthForm />}
            />
            <Route
              path="/user/profile"
              exact="true"
              element={<Profile />}
            />
            <Route
              path="/search"
              exact="true"
              element={<Search />}
            />
            <Route
              path="/about"
              exact="true"
              element={<About />}
            />
            <Route
              path="/privacy"
              exact="true"
              element={<Privacy />}
            />
            <Route
              path="/terms"
              exact="true"
              element={<Terms />}
            />
          </Routes>
        </main>
        {/* <Footer /> */}
      </div>
      <Footer />
    </Router>
  );
}

export default App;
